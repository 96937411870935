.btn-get-started {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  /* padding: 6px 16px; */
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  /* margin: 5px; */
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #999;
  background: #999;
  text-align: center;
  background-color: inherit;
  /* margin-left: 33%; */
  margin-top: 10%;
  border: none;
  outline: none;
  color: #de342f;
}
.drop-down-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
}
.drop-down-button:hover {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
  color: #ff0000 !important;
}
.dropdown-option {
  margin-left: 0px !important;
  border-bottom: 2px solid #fff;
}
.btn-color {
  background: #de342f !important;
  border: 1px solid #de342f !important;
}
.btn-color:hover {
  background: transparent !important;
  color: #fff !important;
}
.innovation {
  color: #de342f !important;
}

.mainBreadCrumbs,
.subBreadCrumbs {
  width: 100%;
  max-width: 100%;
}
.subBreadCrumbs {
  max-width: calc(100% - 100px) !important;
}
.eachItem > div {
  padding: 10px 0 10px 30px;
  font-family: "Poppins";
  font-size: 1rem;
  color: #4f4f4f;
  font-weight: 500;
  padding-left: 2px;
  margin-left: 2.5rem;
  cursor: pointer;
}
.eachItem > div:hover {
  color: #ff0000;
  border-bottom: 2px solid rgb(253, 6, 6);
}
.active {
  color: #ff0000 !important;
  border-bottom: 2px solid rgb(253, 6, 6) !important;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 1200px) {
  .subBreadCrumbs {
    max-width: calc(100% - 80px) !important;
  }
}

@media (max-width: 1200px) {
  .subBreadCrumbs {
    max-width: calc(100% - 32px) !important;
  }
}

@media (max-width: 1040px) {
  .navbarItem ul {
    display: flex;
    gap: 32px;
  }
  .breadCrumbsMain {
    min-width: 124px;
  }
  .navParent {
    flex: 1;
  }
  .eachItem {
    font-size: 15px;
    text-align: center;
  }

  .eachItem > div:hover {
    white-space: wrap;
  }
}

@media (max-width: 700px) {
  .subBreadCrumbs {
    max-width: 100% !important;
    flex-direction: column;
    gap: 0px;
    margin: 0px;
    padding: 0px;
  }
  .breadCrumbsMain {
    width: 100%;
  }
  .navbarItem {
    width: 100%;
    gap: 22px;
    justify-content: space-between;
  }

  .eachItem {
    text-align: left;
    font-size: 12px;
    height: 100%;
    /* border: 1px solid; */
  }
  .eachItem > div {
    margin: 0px;
    word-wrap: break-word;
    white-space: wrap !important;
    overflow-wrap: break-word; /* Force the text to wrap within the anchor element */
    white-space: normal; /*Allow text to wrap */
    display: block;
    border-bottom: 2px solid #fafafa;
    font-weight: 400;
    font-size: 12px;
  }
  .eachItem > div:hover {
    margin: 0px;
    word-wrap: break-word;
    white-space: wrap !important;
    overflow-wrap: break-word; /* Force the text to wrap within the anchor element */
    white-space: normal; /*Allow text to wrap */
    display: block;
    text-align: left;
    font-weight: 400;

    /* border: 1px solid red; */
  }
  .navParent {
    width: 100%;
  }
}

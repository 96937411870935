.imageTextComponentMain {
  display: flex;
  width: 100%;
  border-radius: 8px;
  /* overflow: hidden; */
  margin-top: 60px;
}

.leftMain {
  width: 54%;
  /* max-width: 720px; */
  /* max-height: 400px; */
  /* border-bottom-left-radius: 8px; */
}
.leftMain > img {
  width: 100%;
  /* object-fit: contain; */
  /* max-height: 400px; */
  height: auto;
}

.rightMain {
  flex: 1;
  fill: #fff;
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  display: flex;
  align-items: center;
}
.content {
  width: 90%;
  /* max-width: 422px; */

  margin: 0 auto;
}
.text1 {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text2 {
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 18px;
}
.redirect > a {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #ea3223;
  width: 164px;
  height: 46px;
  color: #ea3223;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  text-decoration: none;
}

@media (max-width: 1250px) {
  .leftMain {
    width: 44%;
  }
  .text1 {
    font-size: 18px;
    font-weight: 600;
  }
  .text2 {
    font-size: 15px;
    font-weight: 500;
  }
  .content {
    width: 100%;
    padding: 0 16px;
  }
}

@media (max-width: 700px) {
  .imageTextComponentMain {
    margin-top: 30px;
    flex-direction: column !important;
  }
  .leftMain {
    width: 100%;
    border-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .text1 {
    font-size: 1.1rem;
    margin-top: 16px;
  }
  .text2 {
    margin-top: 16px;
    font-size: 14px;
  }
  .redirect > a {
    margin-top: 20px;
    width: 148px;
    height: 36px;
    margin-bottom: 20px;
  }
}

/* .datalist{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 100vh;
    background: #212121;
    font-family: sans-serif;
  } */
/* .datalist{
      background-color: aqua;
  } */
.box {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.card-data {
  margin: 10%;
  position: relative;
  width: 300px;
  height: 350px;
  background: #fff;

  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.card-data .imgBx {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
}

.card-data .details {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  text-align: center;
}

.card-data .details h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: #777;
  text-transform: uppercase;
}

.card-data .details h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
}


.wrappers {
  position: relative;
  perspective: 40em;
  display: grid;
  transform-style: preserve-3d;
  margin: 10px;
  border-radius: 20px;
  text-align: center;
color: #000;

}

.cards-cy {
  grid-area: 1 / 1;
  
  width: 80%;

  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin: 10px;
  color: #000;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 200;
  backface-visibility: hidden;
  box-shadow: 0 0px 5px 1px rgba(0, 1, 0.5, 1);
  text-align: center;border-radius: 20px;
  /* border-radius: 50% 20% / 10% 40%; */

}
a {
  color: inherit;
  
}


h1 {
  text-align: center;
  font-size: 15px;
  font-weight: 900;
}
h1 span{
  text-align: center;
font-size: 15px;
  font-weight: 900;
}


.cards-cy .enclosed a {
  background-color: #545454;
  font-size: 18px;

line-height: 1;
color: whitesmoke;
padding: 0 5px;
text-align: center;
transform: translate(-1px, 1px) scale(0.75);
transform-origin: right center;
/* border-radius: 50% 20% / 10% 40%; */
/* border-radius: 20px; */

}
.cards-cy .enclosed a:hover {
  background-color: #ff0000;
  line-height: 1;
color: #ffffff;
padding: 0 5px;
text-align: center;
transform: translate(-1px, 1px) scale(0.75);
transform-origin: right center;
/* border-radius: 50% 20% / 10% 40%; */
/* border-radius: 20px; */

}

/* .cards-cy .enclosed a {
  background: red;
  line-height: 1;
  color: whitesmoke;
  padding: 0 5px;
  text-align: center;
  transform: translate(-1px, 1px) scale(0.75);
  transform-origin: right center;
  /* border-radius: 50% 20% / 10% 40%; */
  /* border-radius: 20px; */
/* } */ 


.wrappers:before {
  --bw: 1px;
  grid-area: 1 / 1;
  content: '';
  color: #000;
  backface-visibility: hidden;
  height: 100%;
  width: 80%;
  margin-top: calc(-1 * var(--bw));
  margin-left: calc(-1 * var(--bw));
  background: transparent;
  /* transform: translateX(-20px) rotateY(-10deg) rotateX(5deg) scale(1.05); */
  pointer-events: none;
  
  box-sizing: content-box;
  /* border-radius: 50% 20% / 10% 40%; */
  border-radius: 20px;
  text-align: center;



}

.wrappers:hover > div,
.wrappers:hover:before {
  transform: none;
}

.wrappers > div,
.wrappers:before {
  will-change: transform;
  transition: 0.3s transform cubic-bezier(0.25, 0.46, 0.45, 1);
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .cards-cy {
  grid-area: 1 / 1;
  height: 100%;
  width: 100%;
  transform: none;


  
}

}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  

}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}



/* 
.container {
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.container .card {
  position: relative;
  cursor: pointer;
}

.container .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container .card .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container .card:hover .face.face1 {
  background: #ff0057;
  transform: translateY(0);
}

.container .card .face.face1 .content {
  opacity: 0.2;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
}

.container .card .face.face1 .content img {
  max-width: 100px;
}

.container .card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

.container .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  margin: 0;
  padding: 0;
}

.container .card .face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.container .card .face.face2 .content a:hover {
  background: #333;
  color: #fff;
} */


.outerMain {
  height: 90vh;
  width: 100%;
  padding: 0 80px;
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.textWrapperMain {
  width: 100%;
}
.leftMain {
  width: 50%;
  /* height: 100%; */
}
.text1 {
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 5px 12px;
  width: fit-content;
}
.text2 {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  margin-top: 16px;
  color: #fff;
  line-height: 48px; /* 150% */
}
.text3 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: "Poppins";
  /* font-size: 18px; */
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}
.lastButton {
  margin-top: 40px;
  text-decoration: none;
  display: flex !important;
  height: 50px;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  text-transform: capitalize;
  border-radius: 25px;
  width: 175px;
  height: 50px;
  justify-content: center;
  background: #ea3223;
}
.lastButton:hover {
  color: #fff;
}
.rightArrow {
  height: 24px;
  width: 24px;
}

.whatWeDoMain {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.titlesMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.two {
  color: #212121;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.three {
  color: #212121;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.four > a {
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #ea3223;
  width: 121px;
  height: 50px;
  justify-content: center;
  color: #ea3223;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  text-decoration: none;
}
.videoOverLap {
  /* border: 1px solid; */
  position: absolute;
  top: 70px;
  width: 40vw;
  left: 50px;
  height: 80vh;
  display: flex;
  align-items: center;
}
.mainKeyFeatures {
  margin-top: 40px;
  width: calc(100% - 100px);
  margin: 0 auto;
}
.keyFeaturesWrapper {
  margin-top: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
}
.eachRow {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 40px;
}
.eachFeature {
  width: 100%;
  border-radius: 8px;
  background: #f66255;
  padding: 30px;
}
.line1 {
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 66px;
}
.eachTitle {
  color: #fff;
  font-family: "Poppins";
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.eachDesc {
  color: #fff;
  font-family: "Poppins";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 14px;
}
.featureTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}
.featuredesc {
  color: #212121;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.growTogether {
  width: calc(100% - 80px);
  /* max-width: 1200px; */

  margin: 0 auto;
  margin-top: 80px !important;
  border-radius: 8px;
  /* background: #bcbcbc; */
  background: #b3b3b3;
  display: flex;
  height: 416px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;
  overflow: hidden;
  align-items: center;
}
.growTextContainer {
  font-family: "Poppins";
}
.growTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.growDesc {
  color: #212121;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 8px;
}
.growButton > a {
  text-decoration: none;
  display: flex;
  width: 171px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #ea3223;
  color: #ea3223;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.growImageLarge {
  height: 100%;
}
.arrows {
  position: absolute;
  bottom: 50px;
  right: 0;
  display: flex;
  gap: 40px;
}
.eachArrow {
  cursor: pointer;
}
.mobileScreen {
  display: none;
}
.laptopScreen {
  display: block;
}
.growImagePhone {
  display: none;
}

.parteners {
  margin-top: 40px;
  width: calc(100% - 100px);
  margin: 0 auto;
}
.partenersTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}
.partenerImages > img {
  width: 100%;
  max-width: 350px;
  height: auto;
  max-height: 100px;
  margin-top: 60px;
}
@media (max-width: 1250px) {
  .videoWrapper {
    max-height: 600px;
  }
  .videoOverLap {
    left: 40px;
    width: 38vw;
    max-height: 600px;
  }
  .textWrapperMain {
    /* border: 1px solid; */
    /* height: 100%; */
  }
  .text2 {
    font-size: 26px;
    font-weight: 600;
  }
  .text3 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 24px;
  }
  .arrows {
    bottom: 30px;
  }
  .whatWeDoMain {
    margin-top: 40px;
  }
  .two {
    font-size: 26px;
  }
  .whatWeDoMain {
    width: calc(100% - 80px);
  }
  .text1 {
  }
  .mainKeyFeatures {
    width: calc(100% - 80px);
  }
  .eachTitle {
    font-size: 1.12rem;
  }
  .eachDesc {
    font-size: 0.9rem;
  }
  .growTogether {
    max-height: 260px;
    margin-top: 60px !important;
    padding-left: 40px;
  }
  .growTitle {
    font-size: 24px;
    font-weight: 400;
    min-width: 236px;
  }
  .growButton > a {
    font-size: 1rem;
    width: 148px;
  }
}

@media (max-width: 1100px) {
  .eachRow {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .laptopScreen {
    display: none;
  }
  .mobileScreen {
    display: block;
    background: linear-gradient(94deg, #8e9499 0%, #142237 82.35%);
    margin-top: -5px;
  }
  .videoContent {
    /* background: linear-gradient(90deg, #8e9499 0%, #142237 96.35%); */
  }
  .textWrapperMainMobile {
    width: 100%;
    text-align: center;
    padding: 0 18px;
  }
  .text2 {
    text-align: center;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    margin-top: 20px;
  }
  .text3 {
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
  .arrows {
    position: relative;
    bottom: 0px;
    gap: 50px;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .whatWeDoMain {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .two {
    font-size: 1.12rem;
  }
  .mainKeyFeatures {
    width: calc(100% - 32px);
  }
  .featureTitle {
    font-size: 1.12rem;
    margin-top: 40px;
  }
  .keyFeaturesWrapper {
    margin-top: 30px;
  }
  .eachRow {
    grid-template-columns: repeat(1, 1fr);
  }
  .bottom2 {
    margin-top: 30px !important;
  }

  .growTogether {
    max-height: fit-content !important;
    padding-left: 0px;
    flex-direction: column-reverse;
    height: fit-content;
    gap: 24px;
    width: calc(100% - 32px);
    margin-top: 40px !important;
  }
  .growImageLarge {
    width: 100%;
  }
  .plantVideo {
    width: 100%;
    height: auto !important;
  }
  .growTextContainer {
    text-align: center;
  }
  .growTitle {
    font-size: 20px;
    width: fit-content;
  }
  .growButton > a {
    margin: 0 auto;
    font-size: 1.12rem;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .parteners {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .partenerImages > img {
    margin-top: 20px;
    max-width: 90%;
  }
}

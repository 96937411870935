
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; 
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;

  


}



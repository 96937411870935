.headerWrapper {
  /* background: linear-gradient(90deg, #8e9499 0%, #142237 96.35%) !important;
  box-shadow: 4px 4px 4px 0px rgba(101, 109, 120, 0.3) !important; */
  background: #e7e7e7 !important;
  box-shadow: 3px 3px 4px 0px rgba(33, 33, 33, 0.04),
    -2px -2px 4px 0px rgba(33, 33, 33, 0.06) !important;
}
@media (min-width: 1200px) {
  .mainHeaderContainer {
    max-width: calc(100% - 100px) !important;
  }
}

.dropbtn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  /* color: #545454; */
  /* color: #fff; */
  color: #4f4f4f;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
}

.dropbtn:hover {
  margin-left: 2.5rem;
  color: #ff0000 !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.links {
  margin-left: 0px !important;
  padding: 10px 20px !important;
  border-bottom: 2px solid #fff;
}

.logocontainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.officialPartner {
  /* color: #545454; */
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  /* font-style: italic; */
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-left: 0px;
  /* border-bottom: 1px solid #908f8c; */
  /* border: 1px solid red; */
  cursor: pointer;
  font-size: 1rem;
  display: none;
}

@media (max-width: 1200px) {
  .mainHeaderContainer {
    max-width: calc(100% - 80px) !important;
    padding: 0px !important;
  }
  .dropbtn {
    margin-left: 1rem;
  }
  .dropbtn:hover {
    margin-left: 1rem;
  }
}

@media (max-width: 970px) {
  .paddingLeft > a {
    padding: 10px 0px !important;
    width: fit-content;
    border-bottom: none !important;
  }
  .logocontainer {
    max-width: 92px;
  }
}

@media (max-width: 700px) {
  .mainHeaderContainer {
    max-width: calc(100% - 32px) !important;
    padding: 0px !important;
  }
  .dropbtn {
    margin-left: 1rem;
  }
  .dropbtn:hover {
    margin-left: 1rem;
  }
}

@media (max-width: 500px) {
  .officialPartner {
    font-size: 0.6rem;
  }
}

.crtHome {
  width: calc(100% - 100px);
  margin: 0 auto;
}
.eachItem {
  margin-top: 110px;
}
.crtHome > div:nth-child(1) {
  margin-top: 0px;
}
.eachTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.eachObj {
  display: flex;
  margin-top: 40px;
  /* height: 100%; */
  /* align-items: center; */
}
.objImage {
  flex: 0 0 46%;
}
.objImage > img {
  width: 100%;
  height: auto;
  height: 100%;
}
.objDesc {
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  padding: 24px;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin: auto 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.objDesc > div {
  margin-top: 20px;
}
.objDesc > div:nth-child(1) {
  margin-top: 0px;
  border-radius: 8px;
}

.lastImage {
  max-width: 664px;
  margin: 0 auto;
}

.lastImage > img {
  width: 100%;
  margin-top: 80px;
}

@media (max-width: 1200px) {
  .crtHome {
    width: calc(100% - 80px);
  }
  .eachItem {
    margin-top: 60px;
  }
  .eachObj {
    flex-direction: column;
  }
  .eachTitle {
    font-size: 1.625rem;
  }
  .objDesc {
    padding: 20px 16px;
  }
  .lastImage > img {
    margin-top: 40px;
  }
}

@media (max-width: 700px) {
  .crtHome {
    width: calc(100% - 32px);
    margin-top: -30px;
  }
  .eachItem {
    margin-top: 40px;
  }
  .eachObj {
    flex-direction: column;
    margin-top: 30px;
  }
  .eachTitle {
    font-size: 1rem;
  }
  .objDesc {
    padding: 16px;
    font-size: 0.9rem;
  }
  .lastImage > img {
    margin-top: 40px;
  }
}

.Header_headerWrapper__143vb {
  /* background: linear-gradient(90deg, #8e9499 0%, #142237 96.35%) !important;
  box-shadow: 4px 4px 4px 0px rgba(101, 109, 120, 0.3) !important; */
  background: #e7e7e7 !important;
  box-shadow: 3px 3px 4px 0px rgba(33, 33, 33, 0.04),
    -2px -2px 4px 0px rgba(33, 33, 33, 0.06) !important;
}
@media (min-width: 1200px) {
  .Header_mainHeaderContainer__3rqkR {
    max-width: calc(100% - 100px) !important;
  }
}

.Header_dropbtn__epxEI {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  /* color: #545454; */
  /* color: #fff; */
  color: #4f4f4f;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
}

.Header_dropbtn__epxEI:hover {
  margin-left: 2.5rem;
  color: #ff0000 !important;
}
.Header_dropdown__3Hd2a {
  position: relative;
  display: inline-block;
}

.Header_dropdownContent__Azqvs {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
}

.Header_dropdownContent__Azqvs a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.Header_dropdownContent__Azqvs a:hover {
  background-color: #ddd;
}

.Header_dropdown__3Hd2a:hover .Header_dropdownContent__Azqvs {
  display: block;
}

.Header_links__yp6gP {
  margin-left: 0px !important;
  padding: 10px 20px !important;
  border-bottom: 2px solid #fff;
}

.Header_logocontainer__3J2Mm {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.Header_officialPartner__N91dL {
  /* color: #545454; */
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  /* font-style: italic; */
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-left: 0px;
  /* border-bottom: 1px solid #908f8c; */
  /* border: 1px solid red; */
  cursor: pointer;
  font-size: 1rem;
  display: none;
}

@media (max-width: 1200px) {
  .Header_mainHeaderContainer__3rqkR {
    max-width: calc(100% - 80px) !important;
    padding: 0px !important;
  }
  .Header_dropbtn__epxEI {
    margin-left: 1rem;
  }
  .Header_dropbtn__epxEI:hover {
    margin-left: 1rem;
  }
}

@media (max-width: 970px) {
  .Header_paddingLeft__3B0MF > a {
    padding: 10px 0px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: none !important;
  }
  .Header_logocontainer__3J2Mm {
    max-width: 92px;
  }
}

@media (max-width: 700px) {
  .Header_mainHeaderContainer__3rqkR {
    max-width: calc(100% - 32px) !important;
    padding: 0px !important;
  }
  .Header_dropbtn__epxEI {
    margin-left: 1rem;
  }
  .Header_dropbtn__epxEI:hover {
    margin-left: 1rem;
  }
}

@media (max-width: 500px) {
  .Header_officialPartner__N91dL {
    font-size: 0.6rem;
  }
}

body {
  font-family: "Poppins" !important;
  color: #444444;
}

a {
  color: #ff0000;
  text-decoration: none;
}

a:hover {
  color: #ff0000;
  text-decoration: none;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: "Poppins";
}
h4 {
  font-family: "Poppins";
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ff0000;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #b02a2a;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #545454;
  padding: 10px 0;
  font-size: 14px;
}
#topbar .contact-info i {
  font-style: normal;
  color: #ff0000;
}
#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}
#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}
#topbar .contact-info i a:hover {
  color: #ff0000;
}
#topbar .social-links a {
  color: #bababa;
  line-height: 0;
  transition: 0.3s;
  margin-left: 10px;
}
#topbar .social-links a:hover {
  color: white;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  margin-bottom: 5px;

  box-shadow: 20px 20px 75px grey;
}
#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  border-left: 8px solid #ff0000;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins";
  font-size: 1rem;
  /* color: #fff; */
  color: #4f4f4f;
  white-space: nowrap;
  font-weight: 500;
  /* transition: 0.3s; */
  padding-left: 2px;
  margin-left: 2.5rem;
  text-decoration: none;
}
@media (max-width: 1170px) {
  .navbar a,
  .navbar a:focus {
    margin-left: 1rem;
  }
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #ff0000;

  border-bottom: 2px solid rgb(253, 6, 6);
}
.navbar .getstarted,
.navbar .getstarted:focus {
  background: #ff0000;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}
.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #ec7f6d;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #545454;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #ff0000;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #545454;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed !important;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(59, 59, 59, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #545454;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #ff0000;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #ff0000;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(59, 59, 59, 0.8);
  overflow: hidden;
  padding: 0;
  margin-bottom: -120px;
  position: relative;
  z-index: 1;
}
#hero .carousel-item {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center center;
}
#hero .carousel-item-services {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-position: center center;
}
#hero .carousel-item::before {
  content: "";
  /* background-color: rgba(255, 255, 255, 0.6); */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.carousel-container {
  justify-content: center;
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 120px;
}
#hero .carousel-content {
  text-align: center;
}
#hero h2 {
  margin-bottom: 40px;
  font-size: 231%;
  font-weight: 700;
  /* background-color: rgba(255, 255, 255, 0.6); */
}
#hero p {
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 10px;
  margin: 10px;
  /* font-weight: 700; */
  /* background-color: rgba(255, 255, 255, 0.6); */
}
#hero p span {
  color: #ff0000;
}
#hero p .h2 {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 20px;
  /* font-weight: 700; */
}
#hero p {
  width: 100%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.5s;
  margin: 30px auto;
  color: honeydew;
  font-size: x-large;
}
#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}
#hero .carousel-inner .carousel-item-services {
  transition-property: opacity;
  background-position: center top;
}
#hero .carousel-inner .carousel-item,
#hero .carousel-inner .carousel-item-services,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}
#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-services,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
}
#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
  color: #545454;
}
#hero .carousel-indicators li {
  cursor: pointer;
  background: #ff0000;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 180px;
  opacity: 0.2;
}
#hero .carousel-indicators li.active {
  opacity: 1;
}
#hero .btn-get-started {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ff0000;
  background: #ff0000;
}
#hero .btn-get-started:hover {
  background: transparent;
  color: #ff0000;
  text-decoration: none;
}
@media (max-height: 300px), (max-width: 750px) {
  #hero {
    margin-bottom: 5%;
  }
  #hero .carousel-container {
    padding-bottom: 2%;
  }
  #hero .carousel-indicators li {
    margin-bottom: 2%;
  }
  #hero h2 {
    margin: auto;
    font-size: 100%;
    /* font-weight: 500; */
    /* background-color: rgba(255, 255, 255, 0.6); */
  }
  #hero p .h2 {
    font-size: inherit;
  }
  #hero .btn-get-started {
    padding: 4px 5px;
  }
  #hero p {
    font-size: inherit;
  }
  #hero .btn-get-started {
    font-size: 10px;
  }
}
@media (max-height: 768px), (max-width: 1200px) {
  /* #hero {
    margin-bottom: 1%;
  } */
  #hero .carousel-container {
    padding-bottom: 2%;
  }
  #hero .carousel-indicators li {
    margin-bottom: 2%;
  }
  /* #hero .btn-get-started {
    padding: 0px 0px;
  } */
}
@media (max-width: 768px) {
  #hero {
    height: 90vh;
  }
  #hero .carousel-item {
    height: 90vh;
  }
  #hero .carousel-item-services {
    height: 90vh;
  }
}
@media (min-width: 1024px) {
  #hero p {
    width: 50%;
  }
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
  /* #hero .btn-get-started {
    padding: 0px 0px;
  } */
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 65px;
}
.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins";
  color: #212121;
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  /* background: #ff0000; */
  bottom: 0;
  left: calc(50% - 25px);
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0 20px 0;
  background: #e7e7e730;
  border-bottom: 1px solid #ededed;
  margin-bottom: 60px;
}
.breadcrumbs .breadCrumbsFullScreen {
  max-width: calc(100% - 100px) !important;
}
@media (max-width: 1200px) {
  .breadcrumbs .breadCrumbsFullScreen {
    max-width: calc(100% - 80px) !important;
  }
}

@media (max-width: 700px) {
  .breadcrumbs .breadCrumbsFullScreen {
    max-width: calc(100% - 32px) !important;
  }
}

.breadcrumbs h2 {
  /* font-size: 28px; */
  font-size: 1rem;
  font-weight: 700;
  color: #545454;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  text-decoration: none;
}
.breadcrumbs ol a {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  text-decoration: none;
}
.breadcrumbs ol li + li {
  padding-left: 5px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 5px;
  color: #6e6e6e;
  content: "/";
}
.breadcrumbs_services {
  padding: 20px 0 20px 0;
  background: #f7f7f7;
  border-bottom: 1px solid #ededed;
  margin-bottom: 0px;
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/
.featured {
  position: relative;
  z-index: 2;
}
.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}
.featured .icon-box i {
  color: #ff0000;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
  line-height: 0;
}
.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}
.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}
.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.featured .icon-box:hover {
  background: #ff0000;
}
.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about-image {
  width: 100%;
  max-height: 400px;
}
.about-image > img {
  object-fit: fill;
  width: 100%;
}
.about-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 32px;
  color: #000000;
  margin-top: 28px;
  text-align: justify;
}
/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services .icon-boxs {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  background: whitesmoke;

  border-radius: 5%;

  transition: all 0.3s ease-out 0s;

  transition: all ease-in-out 0.3s;
}
.services .icon-box {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  background: rgb(20, 20, 25);

  border-radius: 5%;

  transition: all 0.3s ease-out 0s;

  transition: all ease-in-out 0.3s;
}

.cards {
  margin: 19px;
  border: 2px solid black;
  color: rgb(5, 5, 5);
  border-radius: 5%;
  font-size: larger;
  padding: 10px;
  box-shadow: 5px 10px #adacad;
  background-color: rgb(255, 255, 255);
}
@media (max-height: 300px), (max-width: 750px) {
  .cards {
    margin: 15px auto 15px auto;
  }
}
@media (max-height: 768px), (max-width: 1200px) {
  .cards {
    /* margin: 10px 10px 10px 5px; */
  }
}

.cards:hover {
  -webkit-transform: scale(
    1.05
  );
          transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.services .icon-boxs .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ff0000;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ff0000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.services .icon-boxs .icon i {
  color: #fff;
  font-size: 28px;
}
.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-boxs .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fbe2dd;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
}
.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #ededed;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
}

.services .icon-boxs h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 800;
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 800;
}

.services .icon-boxs h4 a {
  /* color: #f8f4f4; */
  font-size: smaller;
  color: rgb(5, 5, 5);
}
.services .icon-box h4 a {
  color: #ededed;
  font-size: smaller;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-boxs p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .services .icon-boxs .services-p {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
  .services .icon-box .services-p {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}
.services .icon-boxs .services-p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 100%;
  max-width: 100%;
}
.services .icon-box .services-p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 100%;
  max-width: 100%;
}

.services .icon-boxs:hover {
  background: #ff0000;
  border-color: #ff0000;
}
.services .icon-box:hover {
  background: #ff0000;
  border-color: #ff0000;
}

.services .icon-boxs:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-boxs:hover .icon i {
  color: #ff0000;
}
.services .icon-box:hover .icon i {
  color: #ff0000;
}

.services .icon-boxs:hover .icon::before {
  background: #ef9383;
}
.services .icon-box:hover .icon::before {
  background: #ef9383;
}

.services .icon-boxs:hover h4 a,
.services .icon-boxs:hover p {
  color: #fff;
}
.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.swiper-slide {
  width: 250px;
  /* margin-right: 120px */
  margin: auto;
}
.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ff0000;
  width: 116px;
  margin-right: 120px;
}
.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ff0000;
}
.clients .swiper-slide img {
  /* opacity: 0.5; */
  transition: 0.3s;
  /* filter: grayscale(100); */
}
.clients .swiper-slide img:hover {
  -webkit-filter: none;
          filter: none;
  opacity: 1;
}
/* .patners-image{
      height: 225px;
    width: 100%;
} */
@media only screen and (min-width: 321px) {
  .patners-image {
    height: auto;
    width: 100%;
  }
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%; */
  /* } */

  .patners-image {
    height: auto;
    width: 100%;
  }
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */

  .img-fluid-clients {
    max-width: 90%;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}
.counts .count-box i {
  display: block;
  font-size: 30px;
  color: #ff0000;
  float: left;
  line-height: 0;
}
.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #545454;
  margin-left: 50px;
}
.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
}
.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7a7a7a;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}
.counts .count-box a:hover {
  color: #a1a1a1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: antiquewhite;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: white;
  font-size: 26px;
  color: #4f4f4f;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  color: #4f4f4f;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.skills .content ul {
  list-style: none;
  padding: 0;
}
.skills .content ul li {
  padding-bottom: 10px;
}
.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}
.skills .content p:last-child {
  margin-bottom: 0;
}
.skills .progress {
  height: 62px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #545454;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: #e0e0e0;
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #ff0000;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  background: #f2f2f2;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #ff0000;
  color: #fff;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(84, 84, 84, 0.6);
}
.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(84, 84, 84, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ff0000;
}
.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ff0000;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #ff0000;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(84, 84, 84, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}
.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}
.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}
.pricing h4 {
  font-size: 46px;
  color: #ff0000;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}
.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}
.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}
.pricing ul li {
  padding-bottom: 12px;
}
.pricing ul i {
  color: #ff0000;
  font-size: 18px;
  padding-right: 4px;
}
.pricing ul .na {
  color: #ccc;
}
.pricing ul .na i {
  color: #ccc;
}
.pricing ul .na span {
  text-decoration: line-through;
}
.pricing .buy-btn {
  background: #545454;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  transition: 0.3s;
}
.pricing .buy-btn:hover {
  background: #ff0000;
}
.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}
.pricing .featured .buy-btn {
  background: #ff0000;
}
.pricing .featured .buy-btn:hover {
  background: #b02a2a;
}
@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}
.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}
.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}
.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}
.team .member .social {
  margin-top: 15px;
}
.team .member .social a {
  color: #919191;
  transition: 0.3s;
}
.team .member .social a:hover {
  color: #ff0000;
}
.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
.contact .info-box-socialmedia {
  color: #444444;
  text-align: left;

  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 40px 0 30px 0;
}

.contact .info-box-socialmedia i {
  font-size: 32px;
  color: #ff0000;
  border-radius: 80%;
  padding: 10px;
  border: 2px dotted #fef5f4;
}
.contact .info-box-socialmedia span {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin-left: 10px 0;
}
.contact .info-box-socialmedia span span {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .info-box i {
  font-size: 32px;
  color: #ff0000;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}
.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}
.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ff0000;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
  background: #ff0000;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #e6573f;
}
@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}
.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.sideBanner {
  height: -webkit-fill-available !important;
}
.blog .entry .entry-img {
  /* max-height: 540px; */
  margin: -30px -30px 20px -30px;
  overflow: hidden;
  background-size: 100% 100%;
}
.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}
.blog .entry .entry-title-h3 {
  font-size: 22px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #545454;
  transition: 0.3s;
}
.blog .entry .entry-title a:hover {
  color: #ff0000;
}
.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #bababa;
}
.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}
.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}
.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}
.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}
.blog .entry .entry-content p {
  line-height: 24px;
  font-size: 18px;
}
.blog .entry .entry-content .read-more {
  text-align-last: right;
}
.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #ff0000;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
  background: #ec7f6d;
}
.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}
.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
  text-align: "justify";
  text-justify: "inter-word";
}
.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #545454;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
  color: #a1a1a1;
  display: inline;
}
.blog .entry .entry-footer a {
  color: #616161;
  transition: 0.3s;
}
.blog .entry .entry-footer a:hover {
  color: #ff0000;
}
.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}
.blog .entry .entry-footer .cats li {
  display: inline-block;
}
.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}
.blog .entry .entry-footer .tags li {
  display: inline-block;
}
.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}
.blog .entry .entry-footer .share {
  font-size: 16px;
}
.blog .entry .entry-footer .share i {
  padding-left: 5px;
}
.blog .entry-single {
  margin-bottom: 30px;
}
.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}
.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #545454;
}
.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
  color: rgba(84, 84, 84, 0.5);
  margin-right: 5px;
}
.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}
.blog .blog-comments {
  margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
  font-weight: bold;
}
.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}
.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
  width: 60px;
}
.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}
.blog .blog-comments .comment h5 a:hover {
  color: #ff0000;
}
.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #545454;
}
.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}
.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #6e6e6e;
  margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}
.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}
.blog .blog-comments .reply-form p {
  font-size: 14px;
}
.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #f5bab0;
}
.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #f5bab0;
}
.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #545454;
}
.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #616161;
}
.blog .blog-pagination {
  color: #878787;
}
.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}
.blog .blog-pagination li a {
  color: #545454;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #ff0000;
}
.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}
.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #545454;
  position: relative;
}
.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}
.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}
.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}
.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #ff0000;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}
.blog .sidebar .search-form form button i {
  line-height: 0;
}
.blog .sidebar .search-form form button:hover {
  background: #eb7b68;
}
.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}
.blog .sidebar .categories ul a {
  color: #545454;
  transition: 0.3s;
}
.blog .sidebar .categories ul a:hover {
  color: #ff0000;
}
.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}
.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}
.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}
.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}
.blog .sidebar .recent-posts h4 a {
  color: #545454;
  transition: 0.3s;
}
.blog .sidebar .recent-posts h4 a:hover {
  color: #ff0000;
}
.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}
.blog .sidebar .tags {
  margin-bottom: -10px;
}
.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .tags ul li {
  display: inline-block;
}
.blog .sidebar .tags ul a {
  color: #949494;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid white;
  display: inline-block;
  transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #ff0000;
  background: #ff0000;
}
.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #ededed;
  font-size: 14px;
}
/* --------------------------------------------------------------
ESG css
--------- */

.ESG-content ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 325px) and (max-width: 650px) {
  .ESG-content ul {
    display: block;
  }
}
.ESG-content ul li {
  padding-bottom: 10px;
}

.ESG-content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #404040;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-newsletter {
  padding: 10px 0;
  background: #404040;
}
#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}
#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}
#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #ff0000;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="submit"]:hover {
  background: #e6573f;
}
#footer .footer-top {
  background: #3b3b3b;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 30px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 20px;
}
#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins";
  color: #fff;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #ff0000;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  font-family: "Poppins";
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ec7f6d;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  /* ea3223 */
}
#footer .footer-top .footer-links ul i :hover {
  color: #ea3232;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}
#footer .footer-top .footer-links ul a:hover {
  color: #ff0000;
}
#footer .footer-top .footer-contact {
  /* margin-bottom: 30px; */
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
.cUmHDP {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.fTcfor {
  font-family: "Poppins";
  word-break: break-word;
  align-self: flex-start;
  padding: 0px 32px;
  text-align: center;
  background-color: "white";
  font-size: 25px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
  margin-bottom: 25px;
}

.carrer-box {
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
/*  */

.bykkjT {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  background-color: white;
  overflow: hidden;
}
.fBWKTz {
  flex: 3 1;
  white-space: nowrap;
  overflow: inherit;
  text-overflow: ellipsis;
  padding-right: 20px;
  text-align: left;
  font-family: "Poppins" !important;
}
.gdpgDF {
  display: inline-block;
  align-items: center;
  padding: 0px 30px;
  height: 70px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 3px 0px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0px;
  flex-wrap: wrap;
}

.hWLfLt {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 23px;
  grid-gap: 20px;
  gap: 20px;
}
.hcLebk span {
  white-space: nowrap;
  overflow: inherit;
  text-overflow: ellipsis;
}
.img-fluid {
  max-width: 100%;
}

/* .banner_images{
  height: 100%;
  width: 100%;
} */

.contactPageH2Tags {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
  font-family: "Poppins";
  line-height: 1.2;
}
.contactPageH3Tags {
  padding: 0;
  line-height: 24px;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
  margin-top: 0;
  color: #000 !important;
}

@media only screen and (min-width: 321px) {
  .banner_images {
    height: "";
    width: "";
  }
  .img-fluid-clients {
    max-width: 50%;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .banner_images {
    height: "";
    width: "";
  }
  .img-fluid-clients {
    max-width: 50%;
  }
  .ESG-content ul {
    display: block;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .banner_images {
    height: 900px;
    width: 900px;
  }

  .img-fluid-clients {
    max-width: 70%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .banner_images {
    height: 700px;
    width: 700px;
  }

  .img-fluid-clients {
    max-width: 70%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%; */
  /* } */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */

  .img-fluid-clients {
    max-width: 90%;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

.wigtes-career {
  width: 665px;
  height: 700px;
  overflow: hidden;
  color: white;
}
.czjTAa:last-child {
  margin-right: 454px;
  margin-top: 5px;
  font-family: "Poppins";
}
.row > span {
  /* border: 1px solid red; */
  padding: 0px;
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1399px) {
  .wigtes-career {
    width: 530px;
    height: 760px;
    overflow: hidden;
    color: white;
  }
  .czjTAa:last-child {
    margin-right: 0px;
  }
}
/* @media only screen and (min-device-width: 1399px) and (max-device-width: 768px) {
  .wigtes-career {
    width: 650px;
    height: 760px;
    overflow: hidden;
    color: white;
  }
} */

.btn-get-started {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  /* padding: 6px 16px; */
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  /* margin: 5px; */
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #999;
  background: #999;
  text-align: center;
  background-color: inherit;
  /* margin-left: 33%; */
  margin-top: 10%;
  border: none;
  outline: none;
  color: #de342f;
}
.drop-down-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
}
.drop-down-button:hover {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem;
  color: #ff0000 !important;
}
.dropdown-option {
  margin-left: 0px !important;
  border-bottom: 2px solid #fff;
}
.btn-color {
  background: #de342f !important;
  border: 1px solid #de342f !important;
}
.btn-color:hover {
  background: transparent !important;
  color: #fff !important;
}
.innovation {
  color: #de342f !important;
}

.careerForm_hubspotWissda__3PKEl * input {
  padding: 20px 30px;
}


.wrappers {
  position: relative;
  -webkit-perspective: 40em;
          perspective: 40em;
  display: grid;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin: 10px;
  border-radius: 20px;
  text-align: center;
color: #000;

}

.cards-cy {
  grid-area: 1 / 1;
  
  width: 80%;

  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin: 10px;
  color: #000;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 200;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 0 0px 5px 1px rgba(0, 1, 0.5, 1);
  text-align: center;border-radius: 20px;
  /* border-radius: 50% 20% / 10% 40%; */

}
a {
  color: inherit;
  
}


h1 {
  text-align: center;
  font-size: 15px;
  font-weight: 900;
}
h1 span{
  text-align: center;
font-size: 15px;
  font-weight: 900;
}


.cards-cy .enclosed a {
  background-color: #545454;
  font-size: 18px;

line-height: 1;
color: whitesmoke;
padding: 0 5px;
text-align: center;
-webkit-transform: translate(-1px, 1px) scale(0.75);
        transform: translate(-1px, 1px) scale(0.75);
-webkit-transform-origin: right center;
        transform-origin: right center;
/* border-radius: 50% 20% / 10% 40%; */
/* border-radius: 20px; */

}
.cards-cy .enclosed a:hover {
  background-color: #ff0000;
  line-height: 1;
color: #ffffff;
padding: 0 5px;
text-align: center;
-webkit-transform: translate(-1px, 1px) scale(0.75);
        transform: translate(-1px, 1px) scale(0.75);
-webkit-transform-origin: right center;
        transform-origin: right center;
/* border-radius: 50% 20% / 10% 40%; */
/* border-radius: 20px; */

}

/* .cards-cy .enclosed a {
  background: red;
  line-height: 1;
  color: whitesmoke;
  padding: 0 5px;
  text-align: center;
  transform: translate(-1px, 1px) scale(0.75);
  transform-origin: right center;
  /* border-radius: 50% 20% / 10% 40%; */
  /* border-radius: 20px; */
/* } */ 


.wrappers:before {
  --bw: 1px;
  grid-area: 1 / 1;
  content: '';
  color: #000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  height: 100%;
  width: 80%;
  margin-top: calc(-1 * var(--bw));
  margin-left: calc(-1 * var(--bw));
  background: transparent;
  /* transform: translateX(-20px) rotateY(-10deg) rotateX(5deg) scale(1.05); */
  pointer-events: none;
  
  box-sizing: content-box;
  /* border-radius: 50% 20% / 10% 40%; */
  border-radius: 20px;
  text-align: center;



}

.wrappers:hover > div,
.wrappers:hover:before {
  -webkit-transform: none;
          transform: none;
}

.wrappers > div,
.wrappers:before {
  will-change: transform;
  transition: 0.3s -webkit-transform cubic-bezier(0.25, 0.46, 0.45, 1);
  transition: 0.3s transform cubic-bezier(0.25, 0.46, 0.45, 1);
  transition: 0.3s transform cubic-bezier(0.25, 0.46, 0.45, 1), 0.3s -webkit-transform cubic-bezier(0.25, 0.46, 0.45, 1);
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .cards-cy {
  grid-area: 1 / 1;
  height: 100%;
  width: 100%;
  -webkit-transform: none;
          transform: none;


  
}

}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  

}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}



/* 
.container {
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.container .card {
  position: relative;
  cursor: pointer;
}

.container .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container .card .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container .card:hover .face.face1 {
  background: #ff0057;
  transform: translateY(0);
}

.container .card .face.face1 .content {
  opacity: 0.2;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
}

.container .card .face.face1 .content img {
  max-width: 100px;
}

.container .card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

.container .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  margin: 0;
  padding: 0;
}

.container .card .face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.container .card .face.face2 .content a:hover {
  background: #333;
  color: #fff;
} */


.container-CS  {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* height: 330px; */
  width: 358px;
  height: 205px;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 0 20px 8px #d0d0d0;
}


.content-CS {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
          transform: translatey(-50%);
  text-align: justify;
  color: black;
  padding: 40px;
  font-family: 'Merriweather', serif;
}


.heading {
  font-weight: 900;
  text-align: center;
  font-size: 25px;
}

.content {
  font-weight: 300;
  font-size: 17px;
}

.flap1 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap1::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url("/static/media/Cloud Security.73d42da5.jpeg") white;
  background-position: 13.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url("/static/media/Cloud Security.73d42da5.jpeg") white;
  background-position: -210px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap1::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap1::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}
/* flap2 */
.flap2 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap2::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url("/static/media/App Security.281c49a7.jpeg") white;
  background-position: 16.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap2::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url("/static/media/App Security.281c49a7.jpeg") white;
  background-position: -220px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap2::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap2::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}



/* flap3 */
.flap3 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap3::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url(/static/media/Vulnerability.356579a4.jpeg) white;
  background-position: 9.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap3::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url(/static/media/Vulnerability.356579a4.jpeg) white;
  background-position: -200px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap3::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap3::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}


/* flap4 */
.flap4 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap4::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url("/static/media/Identity & Access management.934a89c7.jpg") white;
  background-position: 9.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap4::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url("/static/media/Identity & Access management.934a89c7.jpg") white;
  background-position: -208px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap4::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap4::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}

/* flap5 */

.flap5 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap5::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url("/static/media/Endpoint Security.a9120be0.png") white;
  background-position: 9.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap5::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url("/static/media/Endpoint Security.a9120be0.png") white;
  background-position: -200px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap5::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap5::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}




/* flap6 */
.flap6 {
  width: 100%;
  height: 100%;
  /* background: url("./sub-services/Cyber_security_section/Cloud-security.png") white; */
}

.flap6::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: url("/static/media/Managed Service-1.b2f4bcc6.jpg") white;
  background-position: 9.1%;
  background-repeat: no-repeat;
  transition: 1s;
}

.flap6::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: url("/static/media/Managed Service-1.b2f4bcc6.jpg") white;
  background-position: -197px;
  background-repeat: no-repeat;
  transition: 1s;
}

.container-CS:hover .flap6::after {
  -webkit-transform: translatex(300px);
          transform: translatex(300px);
}

.container-CS:hover .flap6::before{
  -webkit-transform: translatex(-300px);
          transform: translatex(-300px);
}


@media only screen and (min-device-width: 300px) and (max-device-width: 750px) {
.container-CS{
   height: 200px;
  width: 300px;
}
.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 23%;
}
.flap2::before {
  background-position: 25%;
}
.flap3::before {
  background-position: 19%;
}
.flap4::before {
  background-position: 19%;
}
.flap5::before {
  background-position: 19%;
}
.flap6::before {
  background-position: 19%;
}
}
@media only screen and (min-device-width: 750px) and (max-device-width: 899px) {
.container-CS{
   height: 200px;
  width: 320px;
}
.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 20%;
}
.flap2::before {
  background-position: 22%;
}
.flap3::before {
  background-position: 16%;
}
.flap4::before {
  background-position: 16%;
}
.flap5::before {
  background-position: 16%;
}
.flap6::before {
  background-position: 16%;
}
}
@media only screen and (min-device-width: 900px) and (max-device-width: 980px) {
.container-CS{
   height: 200px;
  width: 320px;
}
.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 20%;
}
.flap2::before {
  background-position: 22%;
}
.flap3::before {
  background-position: 16%;
}
.flap4::before {
  background-position: 16%;
}
.flap5::before {
  background-position: 16%;
}
.flap6::before {
  background-position: 16%;
}
}
@media only screen and (min-device-width: 980px) and (max-device-width: 990px) {
.container-CS{
   height: 200px;
  width: 330px;
}
.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 18%;
}
.flap2::before {
  background-position: 21%;
}
.flap3::before {
  background-position: 16%;
}
.flap4::before {
  background-position: 16%;
}
.flap5::before {
  background-position: 16%;
}
.flap6::before {
  background-position: 16%;
}
}

@media only screen and (min-device-width: 990px) and (max-device-width: 1020px) {
.container-CS{
   height: 200px;
  width: 293px;
}

.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 24%;
}
.flap2::before {
  background-position: 26%;
}
.flap3::before {
  background-position: 20%;
}
.flap4::before {
  background-position: 20%;
}
.flap5::before {
  background-position: 20%;
}
.flap6::before {
  background-position: 20%;
}

}
  @media only screen and (min-device-width: 1020px) and (max-device-width: 1199px ) {
.container-CS{
   height: 200px;
  width: 280px;
}

.heading{
   font-size: 20px;
}
.flap1::before {
  background-position: 26%;
}
.flap2::before {
  background-position: 28%;
}
.flap3::before {
  background-position: 22%;
}
.flap4::before {
  background-position: 22%;
}
.flap5::before {
  background-position: 22%;
}
.flap6::before {
  background-position: 22%;
}

}

@media only screen and (min-device-width: 1199px) and (max-device-width: 1399px ) {

.container-CS{
   height: 200px;
  width: 300px;
}

.heading{
   font-size: 18px;
}
.flap1::before {
  background-position: 23%;
}
.flap2::before {
  background-position: 25%;
}
.flap3::before {
  background-position: 19%;
}
.flap4::before {
  background-position: 19%;
}
.flap5::before {
  background-position: 19%;
}
.flap6::before {
  background-position: 19%;
}

}

.cyber_containerMain__2l9j6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.cyber_containerMain__2l9j6 > div > img {
  width: 100%;
  border-radius: 40px;
  object-fit: fill;
}
.cyber_containerMain__2l9j6 > div {
  /* overflow: hidden; */
  width: 33% !important;
  grid-gap: 7%;
  gap: 7%;
  height: 300px;
  flex-shrink: unset;
  overflow: hidden;
  cursor: pointer;
}
.cyber_containerMain__2l9j6 > div:nth-child(4),
.cyber_containerMain__2l9j6 > div:nth-child(5),
.cyber_containerMain__2l9j6 > div:nth-child(6) {
  margin-top: 40px;
}

@media (max-width: 990px) {
  .cyber_containerMain__2l9j6 {
    margin: 0 auto;
  }
  .cyber_containerMain__2l9j6 > div {
    padding: 0 16px;
    width: 50% !important;
  }
  .cyber_containerMain__2l9j6 > div:nth-child(3) {
    margin-top: 40px;
  }
}
@media (max-width: 700px) {
  .cyber_containerMain__2l9j6 > div {
    width: 100% !important;
  }
  .cyber_containerMain__2l9j6 {
    margin: 0 auto;
  }
  .cyber_containerMain__2l9j6 > div {
    margin-top: 40px;
  }
}


* {
  margin: 0;
  padding: 0;
}

.hexagonal {
  font-family: 'Open Sans', arial, sans-serif;
  background: rgb(123, 158, 158);
}


 
  .headding{
    font-size: 30px;
    color: darkslategrey;
    padding-top: 38px;
    margin-bottom: 0px;
  }
  .menu-item,
  .menu-open-button {
    background: #eeeeee;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    margin-left: -45px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    line-height: 80px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
    
  }
  
  .menu-open {
    display: none;
    /* visibility: hidden; */
  }
  
  .lines {
    margin: 50%;
    width: 25px;
    height: 3px;
    background: #596778;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -1.5px;

  }

  
  .menu {
    
    margin: auto;
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    text-align: center;
    box-sizing: border-box;
    /* font-size: 10px; */
   
  }
  .Mansoor{
    font-size: 10px;
    text-align: center;
    box-sizing: border-box;
    font-size: larger;
    padding-top: 40px;
    font-weight: bold;
    font-family: none;
  }
  
  .menu-item {
    transition: all 0.1s ease 0s;
  }
  
  .menu-item:hover {
    background: #eeeeee;
    color: #3290b1;
  }
  
  .menu-item:nth-child(3) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(4) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(5) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(6) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(7) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(8) {
    transition-duration: 180ms;
  }
  
  .menu-item:nth-child(9) {
    transition-duration: 180ms;
  }
  
  .menu-open-button {
    z-index: 2;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 400ms;
    -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    cursor: pointer;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  }
  
  .menu-open-button:hover {
    -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    transform: scale(1.2, 1.2) translate3d(0, 0, 0);
  }
  
  .menu-open + .menu-open-button {
    transition-timing-function: linear;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  }
  
  .menu-open ~ .menu-item {
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  }
  
  .menu-open ~ .menu-item:nth-child(3) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
    transform: translate3d(0.08361px, -104.99997px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(4) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
    transform: translate3d(90.9466px, -52.47586px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(5) {
    transition-duration: 380ms;
    -webkit-transition-duration: 380ms;
    -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
    transform: translate3d(90.9466px, 52.47586px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(6) {
    transition-duration: 480ms;
    -webkit-transition-duration: 480ms;
    -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
    transform: translate3d(0.08361px, 104.99997px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(7) {
    transition-duration: 580ms;
    -webkit-transition-duration: 580ms;
    -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
    transform: translate3d(-90.86291px, 52.62064px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(8) {
    transition-duration: 680ms;
    -webkit-transition-duration: 680ms;
    -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
    transform: translate3d(-91.03006px, -52.33095px, 0);
  }
  
  .menu-open ~ .menu-item:nth-child(9) {
    transition-duration: 780ms;
    -webkit-transition-duration: 780ms;
    -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
    transform: translate3d(-0.25084px, -104.9997px, 0);
  }
  
  .blue {
    background-color: #669ae1;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .blue:hover {
    color: #669ae1;
    text-shadow: none;
  }
  
  .green {
    background-color: #70cc72;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .green:hover {
    color: #70cc72;
    text-shadow: none;
  }
  
  .red {
    background-color: #fe4365;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .red:hover {
    color: #fe4365;
    text-shadow: none;
  }
  
  .purple {
    background-color: #c49cde;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .purple:hover {
    color: #c49cde;
    text-shadow: none;
  }
  
  .orange {
    background-color: #fc913a;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .orange:hover {
    color: #fc913a;
    text-shadow: none;
  }
  
  .lightblue {
    background-color: #62c2e4;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  
  .lightblue:hover {
    color: #62c2e4;
    text-shadow: none;
  }
  
  .credit {
    margin: 24px 20px 120px 0;
    text-align: right;
    color: #eeeeee;
  }
  
  .credit a {
    padding: 8px 0;
    color: #c49cde;
    text-decoration: none;
    transition: all 0.3s ease 0s;
  }
  
  .credit a:hover {
    text-decoration: underline;
  }




 #hexGrid {
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  padding: 0.707% 0;
  background-color: #ffffff;
}
/* 
#hexGrid:before {
  content: '';
  display: block;
  clear: both;
} */



.hex {
  position: relative;
  list-style-type: none;
  float: left;
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(-60deg) skewY(30deg);
  transform: rotate(-60deg) skewY(30deg);
}

.hex * {
  position: absolute;
  visibility: visible;
}

.hexIn {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #008080;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate(60deg);
  transform: skewY(-30deg) rotate(60deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #008080;

}

/* HEX CONTENT */

/* .hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
} */

.hex h1 {
  width: 100%;
  padding: 0%;
  color: whitesmoke;
  font-family: 'Raleway', sans-serif;
  
}

.hex h1 {
  content: '';
  display: block;
  position: absolute;
  bottom:-59%;
  align-items: center;
  font-style: italic;
  font-weight: normal;
  font-size: 1em;
  padding-top: 100%;
  padding-bottom: 100%;
}

/* .hex h1 {
  content: '';
display: block;
position: absolute;

    bottom: 0px;
    top: 29%;
    padding-top: 0px;
    padding-bottom: 0px;
    right: 0px;
    left: auto;
    top: 77.387;
    border-bottom-width: 0px;
    margin-bottom: 0px;
    height: 174px;
    width: 224px;
    top: 110.587;
    text-align: center;

} */
/* .hex h1 {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 5%;
    width: 0%;
    text-align: center;
    /* z-index: 1; */
    /* border-bottom: 2px solid #fff; */
/* } */

/* .hex p {
    padding-top: 0px;
    margin-bottom: 0px;
    bottom: auto;
    top: 147px;
} */

/* HOVER EFFECT  */
/* 
.hexIn:hover h1 {
  bottom: 50%;
  padding-bottom: 10%;
}

.hexIn:hover p {
  top: 50%;
  padding-top: 10%;
} */

/* SPACING AND SIZING */

@media (min-width: 1201px) {
  .hex {
    width: 19.2%; /* = (100-4) / 5 */
    padding-bottom: 22.17%; /* =  width / sin(60deg) */
    font-size: 1.3em;

  }
  .hex:nth-child(9n + 6),
  .hex:nth-child(9n + 7),
  .hex:nth-child(9n + 8),
  .hex:nth-child(9n + 9) {
    margin-top: -4.676%;
    margin-bottom: -4.676%;
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  .hex:nth-child(9n + 6):last-child,
  .hex:nth-child(9n + 7):last-child,
  .hex:nth-child(9n + 8):last-child,
  .hex:nth-child(9n + 9):last-child {
    margin-bottom: 0;
  }
  .hex:nth-child(9n + 6) {
    margin-left: 0.5%;
    clear: left;
  }
  .hex:nth-child(9n + 10) {
    margin-left: 40.4%;
    clear: left;

  }
  .hex:nth-child(9n + 2),
  .hex:nth-child(9n + 7) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .hex:nth-child(9n + 3),
  .hex:nth-child(9n + 4),
  .hex:nth-child(9n + 8) {
    margin-right: 1%;
  }
}

@media (max-width: 1200px) and (min-width: 901px) {
  .hex {
    width: 24.25%; /* = (100-3) / 4 */
    padding-bottom: 28.001%; /* =  width / sin(60deg) */
    font-size: 1.3em;

  }
  .hex:nth-child(7n + 5),
  .hex:nth-child(7n + 6),
  .hex:nth-child(7n + 7) {
    margin-top: -6.134%;
    margin-bottom: -6.134%;
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  .hex:nth-child(7n + 5):last-child,
  .hex:nth-child(7n + 6):last-child,
  .hex:nth-child(7n + 7):last-child {
    margin-bottom: 0;
  }
  .hex:nth-child(7n + 2),
  .hex:nth-child(7n + 6) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .hex:nth-child(7n + 3) {
    margin-right: 1%;
  }
  .hex:nth-child(7n + 8) {
    clear: left;
  }
  .hex:nth-child(7n + 5) {
    clear: left;
    margin-left: 0.5%;
  }
}

@media (max-width: 900px) and (min-width: 601px) {
  .hex {
    width: 32.666%; /* = (100-2) / 3 */
    padding-bottom: 37.72%; /* =  width / sin(60) */
    font-size: 1em;

  }
  .hex:nth-child(5n + 4),
  .hex:nth-child(5n + 5) {
    margin-top: -8.564%;
    margin-bottom: -8.564%;
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  .hex:nth-child(5n + 4):last-child,
  .hex:nth-child(5n + 5):last-child {
    margin-bottom: 0;
  }
  .hex:nth-child(5n + 4) {
    margin-right: 1%;
    margin-left: 0.5%;
  }
  .hex:nth-child(5n + 2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .hex:nth-child(5n + 6) {
    clear: left;
  }
}

@media (max-width: 600px) {
  .hex {
    width: 49.5%; /* = (100-1) / 2 */
    padding-bottom: 57.158%; /* =  width / sin(60) */
    font-size: 1em;
  }
  .hex h1{
    bottom: -69%;

  }

  .hex:nth-child(3n + 3) {
    margin-top: -13.423%;
    margin-bottom: -13.423%;
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  .hex:nth-child(3n + 3):last-child {
    margin-bottom: 0;
  }
  .hex:nth-child(3n + 3) {
    margin-left: 0.5%;
  }
  .hex:nth-child(3n + 2) {
    margin-left: 1%;
  }
  .hex:nth-child(3n + 4) {
    clear: left;
  }
}


  
/* .datalist{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 100vh;
    background: #212121;
    font-family: sans-serif;
  } */
/* .datalist{
      background-color: aqua;
  } */
.box {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.card-data {
  margin: 10%;
  position: relative;
  width: 300px;
  height: 350px;
  background: #fff;

  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.card-data .imgBx {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
}

.card-data .details {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  text-align: center;
}

.card-data .details h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: #777;
  text-transform: uppercase;
}

.card-data .details h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
}

.ServicesMenu_mainBreadCrumbs__2QTKH,
.ServicesMenu_subBreadCrumbs__1DwcV {
  width: 100%;
  max-width: 100%;
}
.ServicesMenu_subBreadCrumbs__1DwcV {
  max-width: calc(100% - 100px) !important;
}
.ServicesMenu_eachItem__2NMEL > div {
  padding: 10px 0 10px 30px;
  font-family: "Poppins";
  font-size: 1rem;
  color: #4f4f4f;
  font-weight: 500;
  padding-left: 2px;
  margin-left: 2.5rem;
  cursor: pointer;
}
.ServicesMenu_eachItem__2NMEL > div:hover {
  color: #ff0000;
  border-bottom: 2px solid rgb(253, 6, 6);
}
.ServicesMenu_active__36ELv {
  color: #ff0000 !important;
  border-bottom: 2px solid rgb(253, 6, 6) !important;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 1200px) {
  .ServicesMenu_subBreadCrumbs__1DwcV {
    max-width: calc(100% - 80px) !important;
  }
}

@media (max-width: 1200px) {
  .ServicesMenu_subBreadCrumbs__1DwcV {
    max-width: calc(100% - 32px) !important;
  }
}

@media (max-width: 1040px) {
  .ServicesMenu_navbarItem__zCruo ul {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
  }
  .ServicesMenu_breadCrumbsMain__1p3J9 {
    min-width: 124px;
  }
  .ServicesMenu_navParent__2AKBl {
    flex: 1 1;
  }
  .ServicesMenu_eachItem__2NMEL {
    font-size: 15px;
    text-align: center;
  }

  .ServicesMenu_eachItem__2NMEL > div:hover {
    white-space: wrap;
  }
}

@media (max-width: 700px) {
  .ServicesMenu_subBreadCrumbs__1DwcV {
    max-width: 100% !important;
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
    margin: 0px;
    padding: 0px;
  }
  .ServicesMenu_breadCrumbsMain__1p3J9 {
    width: 100%;
  }
  .ServicesMenu_navbarItem__zCruo {
    width: 100%;
    grid-gap: 22px;
    gap: 22px;
    justify-content: space-between;
  }

  .ServicesMenu_eachItem__2NMEL {
    text-align: left;
    font-size: 12px;
    height: 100%;
    /* border: 1px solid; */
  }
  .ServicesMenu_eachItem__2NMEL > div {
    margin: 0px;
    word-wrap: break-word;
    white-space: wrap !important;
    overflow-wrap: break-word; /* Force the text to wrap within the anchor element */
    white-space: normal; /*Allow text to wrap */
    display: block;
    border-bottom: 2px solid #fafafa;
    font-weight: 400;
    font-size: 12px;
  }
  .ServicesMenu_eachItem__2NMEL > div:hover {
    margin: 0px;
    word-wrap: break-word;
    white-space: wrap !important;
    overflow-wrap: break-word; /* Force the text to wrap within the anchor element */
    white-space: normal; /*Allow text to wrap */
    display: block;
    text-align: left;
    font-weight: 400;

    /* border: 1px solid red; */
  }
  .ServicesMenu_navParent__2AKBl {
    width: 100%;
  }
}

.New_C_RT_crtHome__3wKC1 {
  width: calc(100% - 100px);
  margin: 0 auto;
}
.New_C_RT_eachItem__25831 {
  margin-top: 110px;
}
.New_C_RT_crtHome__3wKC1 > div:nth-child(1) {
  margin-top: 0px;
}
.New_C_RT_eachTitle__1V8zP {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.New_C_RT_eachObj__3Lhvg {
  display: flex;
  margin-top: 40px;
  /* height: 100%; */
  /* align-items: center; */
}
.New_C_RT_objImage__18DDl {
  flex: 0 0 46%;
}
.New_C_RT_objImage__18DDl > img {
  width: 100%;
  height: auto;
  height: 100%;
}
.New_C_RT_objDesc__3TV3b {
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  padding: 24px;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin: auto 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.New_C_RT_objDesc__3TV3b > div {
  margin-top: 20px;
}
.New_C_RT_objDesc__3TV3b > div:nth-child(1) {
  margin-top: 0px;
  border-radius: 8px;
}

.New_C_RT_lastImage__2Ttv- {
  max-width: 664px;
  margin: 0 auto;
}

.New_C_RT_lastImage__2Ttv- > img {
  width: 100%;
  margin-top: 80px;
}

@media (max-width: 1200px) {
  .New_C_RT_crtHome__3wKC1 {
    width: calc(100% - 80px);
  }
  .New_C_RT_eachItem__25831 {
    margin-top: 60px;
  }
  .New_C_RT_eachObj__3Lhvg {
    flex-direction: column;
  }
  .New_C_RT_eachTitle__1V8zP {
    font-size: 1.625rem;
  }
  .New_C_RT_objDesc__3TV3b {
    padding: 20px 16px;
  }
  .New_C_RT_lastImage__2Ttv- > img {
    margin-top: 40px;
  }
}

@media (max-width: 700px) {
  .New_C_RT_crtHome__3wKC1 {
    width: calc(100% - 32px);
    margin-top: -30px;
  }
  .New_C_RT_eachItem__25831 {
    margin-top: 40px;
  }
  .New_C_RT_eachObj__3Lhvg {
    flex-direction: column;
    margin-top: 30px;
  }
  .New_C_RT_eachTitle__1V8zP {
    font-size: 1rem;
  }
  .New_C_RT_objDesc__3TV3b {
    padding: 16px;
    font-size: 0.9rem;
  }
  .New_C_RT_lastImage__2Ttv- > img {
    margin-top: 40px;
  }
}

.NewHome_outerMain__3pR_U {
  height: 90vh;
  width: 100%;
  padding: 0 80px;
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.NewHome_textWrapperMain__3evA5 {
  width: 100%;
}
.NewHome_leftMain__1rPBC {
  width: 50%;
  /* height: 100%; */
}
.NewHome_text1__3qlwj {
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 5px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.NewHome_text2__FeKFT {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  margin-top: 16px;
  color: #fff;
  line-height: 48px; /* 150% */
}
.NewHome_text3__372MV {
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: "Poppins";
  /* font-size: 18px; */
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}
.NewHome_lastButton__30nTw {
  margin-top: 40px;
  text-decoration: none;
  display: flex !important;
  height: 50px;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  text-transform: capitalize;
  border-radius: 25px;
  width: 175px;
  height: 50px;
  justify-content: center;
  background: #ea3223;
}
.NewHome_lastButton__30nTw:hover {
  color: #fff;
}
.NewHome_rightArrow__3Xa5q {
  height: 24px;
  width: 24px;
}

.NewHome_whatWeDoMain__1VLdB {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.NewHome_titlesMain__1SFmS {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NewHome_two__14ojw {
  color: #212121;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.NewHome_three__2IRFG {
  color: #212121;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.NewHome_four__2j5Aq > a {
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #ea3223;
  width: 121px;
  height: 50px;
  justify-content: center;
  color: #ea3223;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  text-decoration: none;
}
.NewHome_videoOverLap__3-5VA {
  /* border: 1px solid; */
  position: absolute;
  top: 70px;
  width: 40vw;
  left: 50px;
  height: 80vh;
  display: flex;
  align-items: center;
}
.NewHome_mainKeyFeatures__1os21 {
  margin-top: 40px;
  width: calc(100% - 100px);
  margin: 0 auto;
}
.NewHome_keyFeaturesWrapper__1Gsn6 {
  margin-top: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
}
.NewHome_eachRow__1GRfe {
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  grid-gap: 40px;
  gap: 40px;
}
.NewHome_eachFeature__1Mbil {
  width: 100%;
  border-radius: 8px;
  background: #f66255;
  padding: 30px;
}
.NewHome_line1__1sOXa {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  min-height: 66px;
}
.NewHome_eachTitle__1Eykq {
  color: #fff;
  font-family: "Poppins";
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.NewHome_eachDesc__3eLqk {
  color: #fff;
  font-family: "Poppins";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 14px;
}
.NewHome_featureTitle__3DZ5S {
  color: #212121;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}
.NewHome_featuredesc__1DpMx {
  color: #212121;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.NewHome_growTogether__PjTmN {
  width: calc(100% - 80px);
  /* max-width: 1200px; */

  margin: 0 auto;
  margin-top: 80px !important;
  border-radius: 8px;
  /* background: #bcbcbc; */
  background: #b3b3b3;
  display: flex;
  height: 416px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;
  overflow: hidden;
  align-items: center;
}
.NewHome_growTextContainer__2iYUi {
  font-family: "Poppins";
}
.NewHome_growTitle__2VIUx {
  color: #212121;
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewHome_growDesc__25l37 {
  color: #212121;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 8px;
}
.NewHome_growButton__1eVuY > a {
  text-decoration: none;
  display: flex;
  width: 171px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #ea3223;
  color: #ea3223;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.NewHome_growImageLarge__vyF2r {
  height: 100%;
}
.NewHome_arrows__311im {
  position: absolute;
  bottom: 50px;
  right: 0;
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.NewHome_eachArrow__3UjWx {
  cursor: pointer;
}
.NewHome_mobileScreen__2OWe- {
  display: none;
}
.NewHome_laptopScreen__1Vq8w {
  display: block;
}
.NewHome_growImagePhone__2tYTV {
  display: none;
}

.NewHome_parteners__Fc3xq {
  margin-top: 40px;
  width: calc(100% - 100px);
  margin: 0 auto;
}
.NewHome_partenersTitle__1orxx {
  color: #212121;
  font-family: "Poppins";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}
.NewHome_partenerImages__18K7T > img {
  width: 100%;
  max-width: 350px;
  height: auto;
  max-height: 100px;
  margin-top: 60px;
}
@media (max-width: 1250px) {
  .NewHome_videoWrapper__3Yhbb {
    max-height: 600px;
  }
  .NewHome_videoOverLap__3-5VA {
    left: 40px;
    width: 38vw;
    max-height: 600px;
  }
  .NewHome_textWrapperMain__3evA5 {
    /* border: 1px solid; */
    /* height: 100%; */
  }
  .NewHome_text2__FeKFT {
    font-size: 26px;
    font-weight: 600;
  }
  .NewHome_text3__372MV {
    font-size: 18px;
    font-weight: 400;
    margin-top: 24px;
  }
  .NewHome_arrows__311im {
    bottom: 30px;
  }
  .NewHome_whatWeDoMain__1VLdB {
    margin-top: 40px;
  }
  .NewHome_two__14ojw {
    font-size: 26px;
  }
  .NewHome_whatWeDoMain__1VLdB {
    width: calc(100% - 80px);
  }
  .NewHome_text1__3qlwj {
  }
  .NewHome_mainKeyFeatures__1os21 {
    width: calc(100% - 80px);
  }
  .NewHome_eachTitle__1Eykq {
    font-size: 1.12rem;
  }
  .NewHome_eachDesc__3eLqk {
    font-size: 0.9rem;
  }
  .NewHome_growTogether__PjTmN {
    max-height: 260px;
    margin-top: 60px !important;
    padding-left: 40px;
  }
  .NewHome_growTitle__2VIUx {
    font-size: 24px;
    font-weight: 400;
    min-width: 236px;
  }
  .NewHome_growButton__1eVuY > a {
    font-size: 1rem;
    width: 148px;
  }
}

@media (max-width: 1100px) {
  .NewHome_eachRow__1GRfe {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .NewHome_laptopScreen__1Vq8w {
    display: none;
  }
  .NewHome_mobileScreen__2OWe- {
    display: block;
    background: linear-gradient(94deg, #8e9499 0%, #142237 82.35%);
    margin-top: -5px;
  }
  .NewHome_videoContent__2kVdB {
    /* background: linear-gradient(90deg, #8e9499 0%, #142237 96.35%); */
  }
  .NewHome_textWrapperMainMobile__3MJKp {
    width: 100%;
    text-align: center;
    padding: 0 18px;
  }
  .NewHome_text2__FeKFT {
    text-align: center;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    margin-top: 20px;
  }
  .NewHome_text3__372MV {
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
  .NewHome_arrows__311im {
    position: relative;
    bottom: 0px;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .NewHome_whatWeDoMain__1VLdB {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .NewHome_two__14ojw {
    font-size: 1.12rem;
  }
  .NewHome_mainKeyFeatures__1os21 {
    width: calc(100% - 32px);
  }
  .NewHome_featureTitle__3DZ5S {
    font-size: 1.12rem;
    margin-top: 40px;
  }
  .NewHome_keyFeaturesWrapper__1Gsn6 {
    margin-top: 30px;
  }
  .NewHome_eachRow__1GRfe {
    grid-template-columns: repeat(1, 1fr);
  }
  .NewHome_bottom2__17dk7 {
    margin-top: 30px !important;
  }

  .NewHome_growTogether__PjTmN {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    padding-left: 0px;
    flex-direction: column-reverse;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 24px;
    gap: 24px;
    width: calc(100% - 32px);
    margin-top: 40px !important;
  }
  .NewHome_growImageLarge__vyF2r {
    width: 100%;
  }
  .NewHome_plantVideo__3BCQe {
    width: 100%;
    height: auto !important;
  }
  .NewHome_growTextContainer__2iYUi {
    text-align: center;
  }
  .NewHome_growTitle__2VIUx {
    font-size: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .NewHome_growButton__1eVuY > a {
    margin: 0 auto;
    font-size: 1.12rem;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .NewHome_parteners__Fc3xq {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .NewHome_partenerImages__18K7T > img {
    margin-top: 20px;
    max-width: 90%;
  }
}

.ImageContext_imageTextComponentMain__DuGOx {
  display: flex;
  width: 100%;
  border-radius: 8px;
  /* overflow: hidden; */
  margin-top: 60px;
}

.ImageContext_leftMain__1RJpE {
  width: 54%;
  /* max-width: 720px; */
  /* max-height: 400px; */
  /* border-bottom-left-radius: 8px; */
}
.ImageContext_leftMain__1RJpE > img {
  width: 100%;
  /* object-fit: contain; */
  /* max-height: 400px; */
  height: auto;
}

.ImageContext_rightMain__2xWKi {
  flex: 1 1;
  fill: #fff;
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  display: flex;
  align-items: center;
}
.ImageContext_content__1eFoa {
  width: 90%;
  /* max-width: 422px; */

  margin: 0 auto;
}
.ImageContext_text1__3EL-U {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ImageContext_text2__37pfO {
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 18px;
}
.ImageContext_redirect__3Vz5i > a {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #ea3223;
  width: 164px;
  height: 46px;
  color: #ea3223;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  text-decoration: none;
}

@media (max-width: 1250px) {
  .ImageContext_leftMain__1RJpE {
    width: 44%;
  }
  .ImageContext_text1__3EL-U {
    font-size: 18px;
    font-weight: 600;
  }
  .ImageContext_text2__37pfO {
    font-size: 15px;
    font-weight: 500;
  }
  .ImageContext_content__1eFoa {
    width: 100%;
    padding: 0 16px;
  }
}

@media (max-width: 700px) {
  .ImageContext_imageTextComponentMain__DuGOx {
    margin-top: 30px;
    flex-direction: column !important;
  }
  .ImageContext_leftMain__1RJpE {
    width: 100%;
    border-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .ImageContext_text1__3EL-U {
    font-size: 1.1rem;
    margin-top: 16px;
  }
  .ImageContext_text2__37pfO {
    margin-top: 16px;
    font-size: 14px;
  }
  .ImageContext_redirect__3Vz5i > a {
    margin-top: 20px;
    width: 148px;
    height: 36px;
    margin-bottom: 20px;
  }
}

.newAbout_newAboutData__1n4iW {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.newAbout_aboutTitle__2_yZF {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.newAbout_aboutData__1fF-v {
  display: flex;
  width: 100%;
  margin-top: 60px;
}
.newAbout_aboutImage__2FVVQ {
  flex: 0 0 40%;
  max-height: calc((403 / 35) * 100vw);
  /* max-width: 540px; */
  /* flex: 1; */
}
.newAbout_aboutImage__2FVVQ > img {
  /* width: 100%;
  height: auto; */
  width: 100%;
  height: 100%;
}
.newAbout_aboutDesc__1K-CL {
  flex: 1 1;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  padding: 36px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newAbout_eachDesc__ikLyP {
  margin-top: 24px;
}
.newAbout_aboutDesc__1K-CL > div:nth-child(1) {
  margin-top: 0px;
}
.newAbout_guidingPrincipal__2mGX3 {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.newAbout_guidingPrincipalTitle__147Gk {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.newAbout_guidingPrincipalItems__1ccou {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}

.newAbout_guidingPrincipalEachItem__UYE93 {
  border-radius: 8px;
  background: #f4f4f4;
  padding: 40px 40px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(50% - 70px);
  /* margin-bottom: 40px; */
  /* min-height: 373px; */
}
.newAbout_gImage__x6qb2 {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newAbout_gTitle__3H5nL {
  color: #212121;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 35px;
}
.newAbout_gdesc__WKUQS {
  color: #4f4f4f;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
}

@media (max-width: 1200px) {
  .newAbout_newAboutData__1n4iW {
    width: calc(100% - 80px);
  }
  .newAbout_aboutData__1fF-v {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
  .newAbout_aboutDesc__1K-CL {
    padding: 20px 16px;
  }
  .newAbout_guidingPrincipal__2mGX3 {
    width: calc(100% - 80px);
  }
  .newAbout_guidingPrincipalItems__1ccou {
    grid-gap: 24px;
    gap: 24px;
  }
  .newAbout_guidingPrincipalEachItem__UYE93 {
    padding: 16px 20px;
  }
  .newAbout_guidingPrincipalTitle__147Gk {
    font-size: 1.625rem;
  }
  .newAbout_gTitle__3H5nL {
    font-size: 1.12rem;
  }
  .newAbout_gdesc__WKUQS {
    font-size: 0.9rem;
  }
}

@media (max-width: 700px) {
  .newAbout_newAboutData__1n4iW {
    width: calc(100% - 32px);
  }
  .newAbout_aboutData__1fF-v {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
  .newAbout_aboutDesc__1K-CL {
    padding: 16px;
    font-size: 0.9rem;
  }
  .newAbout_eachDesc__ikLyP {
    margin-top: 20px;
  }
  .newAbout_guidingPrincipal__2mGX3 {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .newAbout_guidingPrincipalItems__1ccou {
    grid-gap: 24px;
    gap: 24px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .newAbout_guidingPrincipalEachItem__UYE93 {
    padding: 16px 20px;
    width: 100%;
    flex-basis: 100%;
  }
  .newAbout_guidingPrincipalTitle__147Gk {
    font-size: 1.12rem;
  }
  .newAbout_gTitle__3H5nL {
    font-size: 1.12rem;
    margin-top: 22px;
  }
  .newAbout_gdesc__WKUQS {
    font-size: 0.9rem;
  }
}

.NewContact_contactMain__2Ts5O {
  display: flex;
  grid-gap: 217px;
  gap: 217px;
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 50px;
}
.NewContact_left__yFC1W {
  flex: 0 0 38%;
}
.NewContact_leftTitle__1gWTr {
  font-family: "Poppins";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}
.NewContact_leftDesc__2XsjN {
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.032px;
  margin-top: 12px;
}
/* .right {
} */
.NewContact_rightImage__1Q5DR > img {
  width: 100%;
  height: auto;
}
.NewContact_details__1jD1P {
  margin-top: 8px;
}
.NewContact_eachItem__226z0 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
}
.NewContact_eachIcon__26YfL {
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  padding: 7px;
  width: 44px;
  height: 44px;
  color: #ea3223;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.NewContact_itemTitle__3Uaft {
  color: #000;
  font-family: "Poppins";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.NewContact_itemDesc__12Gdi {
  color: #717171;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

@media (max-width: 1200px) {
  .NewContact_contactMain__2Ts5O {
    grid-gap: 32px;
    gap: 32px;
    width: calc(100% - 80px);
  }
  .NewContact_left__yFC1W {
    min-width: 291px;
  }
  .NewContact_leftDesc__2XsjN {
    margin-top: 14px;
    font-size: 0.9rem;
  }
  .NewContact_itemTitle__3Uaft {
    font-size: 1rem;
  }
  .NewContact_itemDesc__12Gdi {
    font-size: 0.9rem;
  }
  .NewContact_eachIcon__26YfL {
    width: 42px;
    height: 42px;
  }
}

@media (max-width: 700px) {
  .NewContact_leftTitle__1gWTr {
    font-size: 1.12rem;
  }
  .NewContact_contactMain__2Ts5O {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    width: calc(100% - 32px);
    margin-top: -20px;
  }
  .NewContact_itemTitle__3Uaft {
    font-size: 0.9rem;
  }
  .NewContact_eachIcon__26YfL {
    width: 36px;
    height: 36px;
  }
  .NewContact_eachItem__226z0 {
    grid-gap: 10px;
    gap: 10px;
  }
}

.contactForm_hubspot__1K6F5 {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 30px;
  margin-top: 24px;
}


body {
  font-family: "Open Sans", sans-serif;
  color: #444444;

  


}




.newAboutData {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.aboutTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.aboutData {
  display: flex;
  width: 100%;
  margin-top: 60px;
}
.aboutImage {
  flex: 0 0 40%;
  max-height: calc((403 / 35) * 100vw);
  /* max-width: 540px; */
  /* flex: 1; */
}
.aboutImage > img {
  /* width: 100%;
  height: auto; */
  width: 100%;
  height: 100%;
}
.aboutDesc {
  flex: 1;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 0px 12px -7px rgba(33, 33, 33, 0.6);
  padding: 36px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.eachDesc {
  margin-top: 24px;
}
.aboutDesc > div:nth-child(1) {
  margin-top: 0px;
}
.guidingPrincipal {
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 60px;
}
.guidingPrincipalTitle {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.guidingPrincipalItems {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}

.guidingPrincipalEachItem {
  border-radius: 8px;
  background: #f4f4f4;
  padding: 40px 40px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  flex: 1 0 calc(50% - 70px);
  /* margin-bottom: 40px; */
  /* min-height: 373px; */
}
.gImage {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gTitle {
  color: #212121;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 35px;
}
.gdesc {
  color: #4f4f4f;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
}

@media (max-width: 1200px) {
  .newAboutData {
    width: calc(100% - 80px);
  }
  .aboutData {
    flex-direction: column;
    gap: 0px;
  }
  .aboutDesc {
    padding: 20px 16px;
  }
  .guidingPrincipal {
    width: calc(100% - 80px);
  }
  .guidingPrincipalItems {
    gap: 24px;
  }
  .guidingPrincipalEachItem {
    padding: 16px 20px;
  }
  .guidingPrincipalTitle {
    font-size: 1.625rem;
  }
  .gTitle {
    font-size: 1.12rem;
  }
  .gdesc {
    font-size: 0.9rem;
  }
}

@media (max-width: 700px) {
  .newAboutData {
    width: calc(100% - 32px);
  }
  .aboutData {
    flex-direction: column;
    gap: 0px;
  }
  .aboutDesc {
    padding: 16px;
    font-size: 0.9rem;
  }
  .eachDesc {
    margin-top: 20px;
  }
  .guidingPrincipal {
    width: calc(100% - 32px);
    margin-top: 40px;
  }
  .guidingPrincipalItems {
    gap: 24px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .guidingPrincipalEachItem {
    padding: 16px 20px;
    width: 100%;
    flex-basis: 100%;
  }
  .guidingPrincipalTitle {
    font-size: 1.12rem;
  }
  .gTitle {
    font-size: 1.12rem;
    margin-top: 22px;
  }
  .gdesc {
    font-size: 0.9rem;
  }
}

body {
  font-family: "Poppins" !important;
  color: #444444;
}

a {
  color: #ff0000;
  text-decoration: none;
}

a:hover {
  color: #ff0000;
  text-decoration: none;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: "Poppins";
}
h4 {
  font-family: "Poppins";
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ff0000;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #b02a2a;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #545454;
  padding: 10px 0;
  font-size: 14px;
}
#topbar .contact-info i {
  font-style: normal;
  color: #ff0000;
}
#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}
#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}
#topbar .contact-info i a:hover {
  color: #ff0000;
}
#topbar .social-links a {
  color: #bababa;
  line-height: 0;
  transition: 0.3s;
  margin-left: 10px;
}
#topbar .social-links a:hover {
  color: white;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  margin-bottom: 5px;

  box-shadow: 20px 20px 75px grey;
}
#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  border-left: 8px solid #ff0000;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #545454;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 2px;
  margin-left: 2.5rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins";
  font-size: 1rem;
  /* color: #fff; */
  color: #4f4f4f;
  white-space: nowrap;
  font-weight: 500;
  /* transition: 0.3s; */
  padding-left: 2px;
  margin-left: 2.5rem;
  text-decoration: none;
}
@media (max-width: 1170px) {
  .navbar a,
  .navbar a:focus {
    margin-left: 1rem;
  }
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #ff0000;

  border-bottom: 2px solid rgb(253, 6, 6);
}
.navbar .getstarted,
.navbar .getstarted:focus {
  background: #ff0000;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}
.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #ec7f6d;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #545454;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #ff0000;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #545454;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed !important;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(59, 59, 59, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #545454;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #ff0000;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #ff0000;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(59, 59, 59, 0.8);
  overflow: hidden;
  padding: 0;
  margin-bottom: -120px;
  position: relative;
  z-index: 1;
}
#hero .carousel-item {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center center;
}
#hero .carousel-item-services {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-position: center center;
}
#hero .carousel-item::before {
  content: "";
  /* background-color: rgba(255, 255, 255, 0.6); */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.carousel-container {
  justify-content: center;
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 120px;
}
#hero .carousel-content {
  text-align: center;
}
#hero h2 {
  margin-bottom: 40px;
  font-size: 231%;
  font-weight: 700;
  /* background-color: rgba(255, 255, 255, 0.6); */
}
#hero p {
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 10px;
  margin: 10px;
  /* font-weight: 700; */
  /* background-color: rgba(255, 255, 255, 0.6); */
}
#hero p span {
  color: #ff0000;
}
#hero p .h2 {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 20px;
  /* font-weight: 700; */
}
#hero p {
  width: 100%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.5s;
  margin: 30px auto;
  color: honeydew;
  font-size: x-large;
}
#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}
#hero .carousel-inner .carousel-item-services {
  transition-property: opacity;
  background-position: center top;
}
#hero .carousel-inner .carousel-item,
#hero .carousel-inner .carousel-item-services,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}
#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-services,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
}
#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}
#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
  color: #545454;
}
#hero .carousel-indicators li {
  cursor: pointer;
  background: #ff0000;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 180px;
  opacity: 0.2;
}
#hero .carousel-indicators li.active {
  opacity: 1;
}
#hero .btn-get-started {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ff0000;
  background: #ff0000;
}
#hero .btn-get-started:hover {
  background: transparent;
  color: #ff0000;
  text-decoration: none;
}
@media (max-height: 300px), (max-width: 750px) {
  #hero {
    margin-bottom: 5%;
  }
  #hero .carousel-container {
    padding-bottom: 2%;
  }
  #hero .carousel-indicators li {
    margin-bottom: 2%;
  }
  #hero h2 {
    margin: auto;
    font-size: 100%;
    /* font-weight: 500; */
    /* background-color: rgba(255, 255, 255, 0.6); */
  }
  #hero p .h2 {
    font-size: inherit;
  }
  #hero .btn-get-started {
    padding: 4px 5px;
  }
  #hero p {
    font-size: inherit;
  }
  #hero .btn-get-started {
    font-size: 10px;
  }
}
@media (max-height: 768px), (max-width: 1200px) {
  /* #hero {
    margin-bottom: 1%;
  } */
  #hero .carousel-container {
    padding-bottom: 2%;
  }
  #hero .carousel-indicators li {
    margin-bottom: 2%;
  }
  /* #hero .btn-get-started {
    padding: 0px 0px;
  } */
}
@media (max-width: 768px) {
  #hero {
    height: 90vh;
  }
  #hero .carousel-item {
    height: 90vh;
  }
  #hero .carousel-item-services {
    height: 90vh;
  }
}
@media (min-width: 1024px) {
  #hero p {
    width: 50%;
  }
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
  /* #hero .btn-get-started {
    padding: 0px 0px;
  } */
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 65px;
}
.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins";
  color: #212121;
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  /* background: #ff0000; */
  bottom: 0;
  left: calc(50% - 25px);
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0 20px 0;
  background: #e7e7e730;
  border-bottom: 1px solid #ededed;
  margin-bottom: 60px;
}
.breadcrumbs .breadCrumbsFullScreen {
  max-width: calc(100% - 100px) !important;
}
@media (max-width: 1200px) {
  .breadcrumbs .breadCrumbsFullScreen {
    max-width: calc(100% - 80px) !important;
  }
}

@media (max-width: 700px) {
  .breadcrumbs .breadCrumbsFullScreen {
    max-width: calc(100% - 32px) !important;
  }
}

.breadcrumbs h2 {
  /* font-size: 28px; */
  font-size: 1rem;
  font-weight: 700;
  color: #545454;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  text-decoration: none;
}
.breadcrumbs ol a {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  text-decoration: none;
}
.breadcrumbs ol li + li {
  padding-left: 5px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 5px;
  color: #6e6e6e;
  content: "/";
}
.breadcrumbs_services {
  padding: 20px 0 20px 0;
  background: #f7f7f7;
  border-bottom: 1px solid #ededed;
  margin-bottom: 0px;
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/
.featured {
  position: relative;
  z-index: 2;
}
.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}
.featured .icon-box i {
  color: #ff0000;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
  line-height: 0;
}
.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}
.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}
.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.featured .icon-box:hover {
  background: #ff0000;
}
.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about-image {
  width: 100%;
  max-height: 400px;
}
.about-image > img {
  object-fit: fill;
  width: 100%;
}
.about-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 32px;
  color: #000000;
  margin-top: 28px;
  text-align: justify;
}
/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services .icon-boxs {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  background: whitesmoke;

  border-radius: 5%;

  transition: all 0.3s ease-out 0s;

  transition: all ease-in-out 0.3s;
}
.services .icon-box {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  background: rgb(20, 20, 25);

  border-radius: 5%;

  transition: all 0.3s ease-out 0s;

  transition: all ease-in-out 0.3s;
}

.cards {
  margin: 19px;
  border: 2px solid black;
  color: rgb(5, 5, 5);
  border-radius: 5%;
  font-size: larger;
  padding: 10px;
  box-shadow: 5px 10px #adacad;
  background-color: rgb(255, 255, 255);
}
@media (max-height: 300px), (max-width: 750px) {
  .cards {
    margin: 15px auto 15px auto;
  }
}
@media (max-height: 768px), (max-width: 1200px) {
  .cards {
    /* margin: 10px 10px 10px 5px; */
  }
}

.cards:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.services .icon-boxs .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ff0000;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ff0000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-boxs .icon i {
  color: #fff;
  font-size: 28px;
}
.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-boxs .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fbe2dd;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #ededed;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-boxs h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 800;
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 800;
}

.services .icon-boxs h4 a {
  /* color: #f8f4f4; */
  font-size: smaller;
  color: rgb(5, 5, 5);
}
.services .icon-box h4 a {
  color: #ededed;
  font-size: smaller;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-boxs p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .services .icon-boxs .services-p {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
  .services .icon-box .services-p {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}
.services .icon-boxs .services-p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 100%;
  max-width: 100%;
}
.services .icon-box .services-p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 100%;
  max-width: 100%;
}

.services .icon-boxs:hover {
  background: #ff0000;
  border-color: #ff0000;
}
.services .icon-box:hover {
  background: #ff0000;
  border-color: #ff0000;
}

.services .icon-boxs:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-boxs:hover .icon i {
  color: #ff0000;
}
.services .icon-box:hover .icon i {
  color: #ff0000;
}

.services .icon-boxs:hover .icon::before {
  background: #ef9383;
}
.services .icon-box:hover .icon::before {
  background: #ef9383;
}

.services .icon-boxs:hover h4 a,
.services .icon-boxs:hover p {
  color: #fff;
}
.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.swiper-slide {
  width: 250px;
  /* margin-right: 120px */
  margin: auto;
}
.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ff0000;
  width: 116px;
  margin-right: 120px;
}
.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ff0000;
}
.clients .swiper-slide img {
  /* opacity: 0.5; */
  transition: 0.3s;
  /* filter: grayscale(100); */
}
.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}
/* .patners-image{
      height: 225px;
    width: 100%;
} */
@media only screen and (min-width: 321px) {
  .patners-image {
    height: auto;
    width: 100%;
  }
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .patners-image {
    height: auto;
    width: 100%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%; */
  /* } */

  .patners-image {
    height: auto;
    width: 100%;
  }
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */

  .img-fluid-clients {
    max-width: 90%;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}
.counts .count-box i {
  display: block;
  font-size: 30px;
  color: #ff0000;
  float: left;
  line-height: 0;
}
.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #545454;
  margin-left: 50px;
}
.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
}
.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7a7a7a;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}
.counts .count-box a:hover {
  color: #a1a1a1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: antiquewhite;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: white;
  font-size: 26px;
  color: #4f4f4f;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  color: #4f4f4f;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.skills .content ul {
  list-style: none;
  padding: 0;
}
.skills .content ul li {
  padding-bottom: 10px;
}
.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}
.skills .content p:last-child {
  margin-bottom: 0;
}
.skills .progress {
  height: 62px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #545454;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: #e0e0e0;
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #ff0000;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  background: #f2f2f2;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #ff0000;
  color: #fff;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(84, 84, 84, 0.6);
}
.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(84, 84, 84, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ff0000;
}
.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ff0000;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #ff0000;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(84, 84, 84, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}
.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}
.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}
.pricing h4 {
  font-size: 46px;
  color: #ff0000;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}
.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}
.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}
.pricing ul li {
  padding-bottom: 12px;
}
.pricing ul i {
  color: #ff0000;
  font-size: 18px;
  padding-right: 4px;
}
.pricing ul .na {
  color: #ccc;
}
.pricing ul .na i {
  color: #ccc;
}
.pricing ul .na span {
  text-decoration: line-through;
}
.pricing .buy-btn {
  background: #545454;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  transition: 0.3s;
}
.pricing .buy-btn:hover {
  background: #ff0000;
}
.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}
.pricing .featured .buy-btn {
  background: #ff0000;
}
.pricing .featured .buy-btn:hover {
  background: #b02a2a;
}
@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}
.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}
.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}
.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}
.team .member .social {
  margin-top: 15px;
}
.team .member .social a {
  color: #919191;
  transition: 0.3s;
}
.team .member .social a:hover {
  color: #ff0000;
}
.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
.contact .info-box-socialmedia {
  color: #444444;
  text-align: left;

  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 40px 0 30px 0;
}

.contact .info-box-socialmedia i {
  font-size: 32px;
  color: #ff0000;
  border-radius: 80%;
  padding: 10px;
  border: 2px dotted #fef5f4;
}
.contact .info-box-socialmedia span {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin-left: 10px 0;
}
.contact .info-box-socialmedia span span {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .info-box i {
  font-size: 32px;
  color: #ff0000;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}
.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}
.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ff0000;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
  background: #ff0000;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #e6573f;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}
.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.sideBanner {
  height: -webkit-fill-available !important;
}
.blog .entry .entry-img {
  /* max-height: 540px; */
  margin: -30px -30px 20px -30px;
  overflow: hidden;
  background-size: 100% 100%;
}
.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}
.blog .entry .entry-title-h3 {
  font-size: 22px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #545454;
  transition: 0.3s;
}
.blog .entry .entry-title a:hover {
  color: #ff0000;
}
.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #bababa;
}
.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}
.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}
.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}
.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}
.blog .entry .entry-content p {
  line-height: 24px;
  font-size: 18px;
}
.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}
.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #ff0000;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
  background: #ec7f6d;
}
.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}
.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
  text-align: "justify";
  text-justify: "inter-word";
}
.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #545454;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
  color: #a1a1a1;
  display: inline;
}
.blog .entry .entry-footer a {
  color: #616161;
  transition: 0.3s;
}
.blog .entry .entry-footer a:hover {
  color: #ff0000;
}
.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}
.blog .entry .entry-footer .cats li {
  display: inline-block;
}
.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}
.blog .entry .entry-footer .tags li {
  display: inline-block;
}
.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}
.blog .entry .entry-footer .share {
  font-size: 16px;
}
.blog .entry .entry-footer .share i {
  padding-left: 5px;
}
.blog .entry-single {
  margin-bottom: 30px;
}
.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}
.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #545454;
}
.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
  color: rgba(84, 84, 84, 0.5);
  margin-right: 5px;
}
.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}
.blog .blog-comments {
  margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
  font-weight: bold;
}
.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}
.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
  width: 60px;
}
.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}
.blog .blog-comments .comment h5 a:hover {
  color: #ff0000;
}
.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #545454;
}
.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}
.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #6e6e6e;
  margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}
.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}
.blog .blog-comments .reply-form p {
  font-size: 14px;
}
.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #f5bab0;
}
.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #f5bab0;
}
.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #545454;
}
.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #616161;
}
.blog .blog-pagination {
  color: #878787;
}
.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}
.blog .blog-pagination li a {
  color: #545454;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #ff0000;
}
.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}
.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #545454;
  position: relative;
}
.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}
.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}
.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}
.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #ff0000;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}
.blog .sidebar .search-form form button i {
  line-height: 0;
}
.blog .sidebar .search-form form button:hover {
  background: #eb7b68;
}
.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}
.blog .sidebar .categories ul a {
  color: #545454;
  transition: 0.3s;
}
.blog .sidebar .categories ul a:hover {
  color: #ff0000;
}
.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}
.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}
.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}
.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}
.blog .sidebar .recent-posts h4 a {
  color: #545454;
  transition: 0.3s;
}
.blog .sidebar .recent-posts h4 a:hover {
  color: #ff0000;
}
.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}
.blog .sidebar .tags {
  margin-bottom: -10px;
}
.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .tags ul li {
  display: inline-block;
}
.blog .sidebar .tags ul a {
  color: #949494;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid white;
  display: inline-block;
  transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #ff0000;
  background: #ff0000;
}
.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #ededed;
  font-size: 14px;
}
/* --------------------------------------------------------------
ESG css
--------- */

.ESG-content ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 325px) and (max-width: 650px) {
  .ESG-content ul {
    display: block;
  }
}
.ESG-content ul li {
  padding-bottom: 10px;
}

.ESG-content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff0000;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #404040;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-newsletter {
  padding: 10px 0;
  background: #404040;
}
#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}
#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}
#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #ff0000;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="submit"]:hover {
  background: #e6573f;
}
#footer .footer-top {
  background: #3b3b3b;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 30px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 20px;
}
#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins";
  color: #fff;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #ff0000;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  font-family: "Poppins";
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ec7f6d;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  /* ea3223 */
}
#footer .footer-top .footer-links ul i :hover {
  color: #ea3232;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}
#footer .footer-top .footer-links ul a:hover {
  color: #ff0000;
}
#footer .footer-top .footer-contact {
  /* margin-bottom: 30px; */
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
.cUmHDP {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.fTcfor {
  font-family: "Poppins";
  word-break: break-word;
  align-self: flex-start;
  padding: 0px 32px;
  text-align: center;
  background-color: "white";
  font-size: 25px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
  margin-bottom: 25px;
}

.carrer-box {
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
/*  */

.bykkjT {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 0px;
  background-color: white;
  overflow: hidden;
}
.fBWKTz {
  flex: 3 1 0%;
  white-space: nowrap;
  overflow: inherit;
  text-overflow: ellipsis;
  padding-right: 20px;
  text-align: left;
  font-family: "Poppins" !important;
}
.gdpgDF {
  display: inline-block;

  -webkit-box-align: center;
  align-items: center;
  padding: 0px 30px;
  height: 70px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 3px 0px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0px;
  flex-wrap: wrap;
}

.hWLfLt {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 23px;
  gap: 20px;
}
.hcLebk span {
  white-space: nowrap;
  overflow: inherit;
  text-overflow: ellipsis;
}
.img-fluid {
  max-width: 100%;
}

/* .banner_images{
  height: 100%;
  width: 100%;
} */

.contactPageH2Tags {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
  font-family: "Poppins";
  line-height: 1.2;
}
.contactPageH3Tags {
  padding: 0;
  line-height: 24px;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
  margin-top: 0;
  color: #000 !important;
}

@media only screen and (min-width: 321px) {
  .banner_images {
    height: "";
    width: "";
  }
  .img-fluid-clients {
    max-width: 50%;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .banner_images {
    height: "";
    width: "";
  }
  .img-fluid-clients {
    max-width: 50%;
  }
  .ESG-content ul {
    display: block;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .banner_images {
    height: 900px;
    width: 900px;
  }

  .img-fluid-clients {
    max-width: 70%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .banner_images {
    height: 700px;
    width: 700px;
  }

  .img-fluid-clients {
    max-width: 70%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%; */
  /* } */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */

  .img-fluid-clients {
    max-width: 90%;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  /* .banner_images {
  height: 100%;
  width: 100%;
} */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

.contactMain {
  display: flex;
  gap: 217px;
  width: calc(100% - 100px);
  margin: 0 auto;
  margin-top: 50px;
}
.left {
  flex: 0 0 38%;
}
.leftTitle {
  font-family: "Poppins";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}
.leftDesc {
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.032px;
  margin-top: 12px;
}
/* .right {
} */
.rightImage > img {
  width: 100%;
  height: auto;
}
.details {
  margin-top: 8px;
}
.eachItem {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
}
.eachIcon {
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  padding: 7px;
  width: 44px;
  height: 44px;
  color: #ea3223;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.itemTitle {
  color: #000;
  font-family: "Poppins";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.itemDesc {
  color: #717171;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

@media (max-width: 1200px) {
  .contactMain {
    gap: 32px;
    width: calc(100% - 80px);
  }
  .left {
    min-width: 291px;
  }
  .leftDesc {
    margin-top: 14px;
    font-size: 0.9rem;
  }
  .itemTitle {
    font-size: 1rem;
  }
  .itemDesc {
    font-size: 0.9rem;
  }
  .eachIcon {
    width: 42px;
    height: 42px;
  }
}

@media (max-width: 700px) {
  .leftTitle {
    font-size: 1.12rem;
  }
  .contactMain {
    flex-direction: column;
    gap: 40px;
    width: calc(100% - 32px);
    margin-top: -20px;
  }
  .itemTitle {
    font-size: 0.9rem;
  }
  .eachIcon {
    width: 36px;
    height: 36px;
  }
  .eachItem {
    gap: 10px;
  }
}

.containerMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.containerMain > div > img {
  width: 100%;
  border-radius: 40px;
  object-fit: fill;
}
.containerMain > div {
  /* overflow: hidden; */
  width: 33% !important;
  gap: 7%;
  height: 300px;
  flex-shrink: unset;
  overflow: hidden;
  cursor: pointer;
}
.containerMain > div:nth-child(4),
.containerMain > div:nth-child(5),
.containerMain > div:nth-child(6) {
  margin-top: 40px;
}

@media (max-width: 990px) {
  .containerMain {
    margin: 0 auto;
  }
  .containerMain > div {
    padding: 0 16px;
    width: 50% !important;
  }
  .containerMain > div:nth-child(3) {
    margin-top: 40px;
  }
}
@media (max-width: 700px) {
  .containerMain > div {
    width: 100% !important;
  }
  .containerMain {
    margin: 0 auto;
  }
  .containerMain > div {
    margin-top: 40px;
  }
}
